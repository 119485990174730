import React, { Component } from "react";
import { Dropdown, Modal, Button } from "react-bootstrap";
// import { Link } from 'react-router-dom';
import { Trans } from "react-i18next";
import avthar from "../../assets/images/avthar.png";
import { Link } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import { ToastContainer, toast } from "react-toastify";
import { toastmsg } from "../../Helper";
import profile from "../../assets/images/profileImage.jpg";
import Spinner from "react-bootstrap/Spinner";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      count: "",
      redirect: false,
      loading: true,
      show: false,
      admin_id: localStorage.getItem("adminId"),
      subadminid:
        localStorage.getItem("subadmin_id") == null
          ? 0
          : localStorage.getItem("subadmin_id"),
      type: localStorage.getItem("type"),
      image: "",
      image1: "",
      uploadImageUrl: "",
    };

    this.openModel = this.openModel.bind(this);
    this.imageChange = this.imageChange.bind(this);
    this.Upload = this.Upload.bind(this);
    this.getNotifications = this.getNotifications.bind(this);
    this.getNotificationsCount = this.getNotificationsCount.bind(this);
    this.Getimage = this.Getimage.bind(this);
  }

  getNotificationsCount() {
    this.intervalId = setInterval(() => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL_NEW}/api/${
            this.state.type == "1" ? "notcount" : "getcountsub"
          }/${
            this.state.type == "1"
              ? localStorage.getItem("adminId")
              : localStorage.getItem("branchId")
          }`,
          {
            headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
          }
        )
        .then((res) => {
          let response = res.data.count;
          this.setState({ count: response });
        });
    }, 10000);
  }

  getNotifications() {
    this.setState({ loading: true });
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/${
          this.state.type == "1" ? "notifications" : "getnotificationdatasub"
        }/${
          this.state.type == "1"
            ? localStorage.getItem("adminId")
            : localStorage.getItem("branchId")
        }`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        let response = res.data.data;
          
        if (res.data.status == "success") {
          this.setState({ notifications: response, count: 0 });
          this.setState({ loading: false });
        }
        this.setState({ loading: false });
      });
  }

  Getimage() {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/getimage/${
          this.state.type == "1"
            ? localStorage.getItem("adminId")
            : localStorage.getItem("subadmin_id")
        }`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        this.setState({ image: res.data.data });
      });
  }

  componentDidMount() {
    const type = localStorage.getItem("type");
    if (type) {
      this.getNotificationsCount();
      this.Getimage();
    }
  }
  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.type !== this.state.type) {
      this.updateBasedOnTypeChange();
    }
  }

  updateBasedOnTypeChange() {
    const type = localStorage.getItem("type");
    if (type) {
      this.getNotificationsCount();
      this.Getimage();
      // You can add more logic here if needed
    }
  }

  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }

  toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }

  openModel() {
    this.setState({ show: true });
  }

  imageChange(e) {
    this.setState({ image1: e.target.files[0] });
    let objectUrl = URL.createObjectURL(e.target.files[0]);
    this.setState({ uploadImageUrl: objectUrl });
  }

  Upload() {
    const payload = new FormData();
    payload.append("image", this.state.image1);
    payload.append(
      "a_id",
      this.state.type == "1" ? this.state.admin_id : this.state.subadminid
    );
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/${
          this.state.type == "1" ? "editadminprofile" : "subadminprofile"
        }`,
        payload,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: process.env.REACT_APP_AUTH_KEY,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          this.setState({ image: res.data.image });
          toastmsg(res.data.message, toast.POSITION.TOP_CENTER, 2000);
          this.setState({ show: false });
        } else {
          toastmsg(res.data.message, toast.POSITION.TOP_CENTER, 2000);
          alert("cannot set image");
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }
  handelImageFileClick(e) {
    let fileBtn = document.getElementById("adminFileInput");
    fileBtn.click();
  }
  render() {
    return (
      <>
        <ToastContainer closeButton={false} hideProgressBar={true} />
        <nav className="navbar default-layout-navbar col-lg-12 col-12  fixed-top d-flex flex-row">
          <div className="navbar-menu-wrapper d-flex align-items-stretch">
            <ul className="navbar-nav navbar-nav-right">
              {/* <li className="nav-item">
          <span className='avthar_name'>Admin</span>
          <img src={this.state.image} width={50} onClick={this.openModel} alt="profile" className="img-fluid brdr_img" />
</li> 165 */}
              <li className="nav-item">
                <span className="avthar_name">
                  {this.state.type === "1" ? "Admin" : "Sub Admin"}
                </span>
                <img
                  src={
                    this.state.image == "" ||
                    this.state.image == undefined ||
                    this.state.image == null
                      ? profile
                      : `${process.env.REACT_APP_BASE_URL_NEW}/${
                          this.state.type == "1"
                            ? "adminprofile"
                            : "subadminprofile"
                        }/${this.state.image}`
                  }
                  width={50}
                  onClick={this.openModel}
                  alt="profile"
                  className="img-fluid brdr_img"
                />
              </li>
              <li className="nav-item">
                <Dropdown alignRight>
                  <Dropdown.Toggle className="nav-link count-indicator">
                    <i
                      className="mdi mdi-bell"
                      onClick={(e) => this.getNotifications()}
                    ></i>
                    {this.state.count > 0 && (
                      <span className="count-symbol bg-danger">
                        {this.state.count}
                      </span>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                    <div className="dropdown-divider"></div>
                    {this.state.notifications.length > 0 ? (
                      this.state.loading ? (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Spinner animation="border" size="sm" />
                        </div>
                      ) : (
                        this.state.notifications.map((values) => (
                          <Dropdown.Item
                            className="dropdown-item preview-item"
                            onClick={(evt) => evt.preventDefault()}
                          >
                            <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                              <h6 className="preview-subject font-weight-normal mb-1">
                                <Trans>{values.email}</Trans>
                              </h6>
                              <p className="text-gray ellipsis mb-0">
                                <Link
                                  className="notify-align"
                                  to={{
                                    pathname:
                                      this.state.type === "1"
                                        ? "/management"
                                        : "/subcomplaint",
                                    state: {
                                      branch_id: values.branch_id,
                                      dep_id: values.dep_id,
                                    },
                                  }}
                                >
                                  <Trans>
                                    Complaint ID- {values.unique_id}
                                  </Trans>
                                </Link>
                              </p>
                            </div>
                          </Dropdown.Item>
                        ))
                      )
                    ) : (
                      <>
                        <p className="text-center">No new complaints</p>
                      </>
                    )}

                    <div className="dropdown-divider"></div>
                    <Link
                      className="notify-align"
                      to={
                        this.state.type == "1" ? "/management" : "/subcomplaint"
                      }
                    >
                      <h6 className="p-3 mb-0 text-center cursor-pointer">
                        <Trans>See all complaints</Trans>
                      </h6>
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
            <button
              className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
              type="button"
              onClick={this.toggleOffcanvas}
            >
              <span className="mdi mdi-menu"></span>
            </button>
          </div>

          <Modal
            size="sm"
            show={this.state.show}
            onHide={() => this.setState({ show: false })}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <div className="avthar_img">
                {this.state.uploadImageUrl != "" ? (
                  <img
                    src={this.state.uploadImageUrl}
                    width={100}
                    height={100}
                    alt="profile"
                    className="edit_avthar"
                  />
                ) : (
                  <img
                    src={
                      this.state.image == "" ||
                      this.state.image == undefined ||
                      this.state.image == null
                        ? profile
                        : `${process.env.REACT_APP_BASE_URL_NEW}/${
                            this.state.type == "1"
                              ? "adminprofile"
                              : "subadminprofile"
                          }/${this.state.image}`
                    }
                    width={100}
                    height={100}
                    alt="profile"
                    className="edit_avthar"
                  />
                )}
              </div>
            </Modal.Header>
            <Modal.Body>
              <div>
                <input
                  className="d-none"
                  type="file"
                  name="image"
                  accept="image/*"
                  id="adminFileInput"
                  onChange={this.imageChange}
                />
                <div>
                  <button
                    onClick={(e) => this.handelImageFileClick(e)}
                    className="upload_btn avthar_img editup_btn"
                  >
                    Upload
                  </button>
                </div>
                <div>
                  <button
                    onClick={this.Upload}
                    className="upload_btn avthar_img editup_btn mt-2"
                  >
                    Save
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </nav>
      </>
    );
  }
}

export default Navbar;
