
import {ToastContainer, Zoom, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../src/app/App.css";


export function toastmsg(message, position, autoClose) {
  //alert(message);return
  const xyz=toast.success(message, {
    position: position, //toast.POSITION.TOP_CENTER
    type: toast.TYPE.DEFAULT,
    autoClose: autoClose, // 2sec
    toastClassName: "green-toast", 
    pauseOnHover: true,
    newestOnTop: true,
    limit:0,
  });
  return xyz;
}

