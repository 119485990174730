import React from "react";
import { Button } from "react-bootstrap";
import "../../screenSaver/screenSaver.css";
import screensaver from "../../../assets/images/icons/uploadbg.png";
import mobileview from "../../../assets/images/icons/mobileview.png";
import laptop from "../../../assets/images/icons/laptop.png";
import smartphone from "../../../assets/images/icons/smartphone.png";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { toastmsg } from "../../../Helper";
import LoadingOverlay from "react-loading-overlay";

const Saver = () => {
  const inputFileRef = React.useRef();
  const [selectedImage, setSelectedImage] = useState();
  const [selectedImage1, setSelectedImage1] = useState();
  const [loading, setLoading] = useState(true);
  const [saver, setsaver] = useState("");
  const [mobilesaver, setMobilesaver] = useState("");
  const [msg, setMsg] = useState("");
  const [branch, setBranch] = useState({ list: [], id: 0 });
  let admin_id = localStorage.getItem("adminId");
  let subAdminId = localStorage.getItem("subadmin_id");
  let branchId = localStorage.getItem("branch_id");

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setsaver(e.target.files[0]);
      setSelectedImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const imagePhone = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setMobilesaver(e.target.files[0]);
      setSelectedImage1(URL.createObjectURL(e.target.files[0]));
    }
  };
  const clickInput = () => {
    // inputFileRef.current.click()
    let hiddenFileInput = document.getElementById("hiddenFileInput");
    hiddenFileInput.click();
  };

  const clickInput1 = () => {
    // inputFileRef.current.click()
    let hiddenFileInput1 = document.getElementById("hiddenFileInput1");
    hiddenFileInput1.click();
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/subbranchlist/${subAdminId}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        let newlist = res.data.data;
        let options = [];
        if (res.data.data) {
          if (newlist?.length > 0) {
            for (let i = 0; i < newlist?.length; i = i + 1) {
              console.log(newlist[i], "item");
              let item = {
                value: newlist[i].b_id,
                label: newlist[i].branch_name,
              };
              options.push(item);
            }
          } else {
            options = [];
          }
          setBranch({ id: res.data.data[0].b_id, list: options });
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);

  useEffect(() => {
    branch?.id != 0 && imagesList();

    branch?.id != 0 && MobileImage();
  }, [branch?.id]);

  const imagesList = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/getScreenSaver/${branch?.id}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        localStorage.setItem("image", res.data.image);
        if (res.data.image !== "" && res.data.image != null) {
          const baseUrl = process.env.REACT_APP_BASE_URL_NEW;
          const imageUrl = localStorage.getItem("image");
          const fullUrl = `${baseUrl}/Screensaver/${imageUrl}`;
          setSelectedImage(fullUrl);
        } else {
          setSelectedImage();
        }
        setLoading(false);
      });
  };

  const MobileImage = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/getmobileScreenSaver/${branch?.id}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        localStorage.setItem("image", res.data.image);
        if (res.data.image !== "" && res.data.image != null) {
          const baseUrl = process.env.REACT_APP_BASE_URL_NEW;
          const imageUrl = localStorage.getItem("image");
          const fullUrl = `${baseUrl}/MobileScreensaver/${imageUrl}`;
          setSelectedImage1(fullUrl);
        } else {
          setSelectedImage1();
        }
        setLoading(false);
      });
  };

  const Updating = (e) => {
    e.preventDefault();
    if (saver != "") {
      const payload = new FormData();
      payload.append("saver", saver && saver);
      payload.append("b_id", branch?.id);

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL_NEW}/api/adddesktopsaver`,
          payload,
          {
            headers: {
              "Content-type": "multipart/form-data",
              Authorization: process.env.REACT_APP_AUTH_KEY,
            },
          }
        )
        .then((res) => {
          let status = res.data.status;
          if (status == "success") {
            setSelectedImage("");
            let FileInput = document.getElementById("hiddenFileInput");
            FileInput.value = "";
            // setMsg("Image uploaded sucessfully");
            toastmsg(res.data.message, toast.POSITION.TOP_CENTER, 2000);
            setsaver("");
            imagesList();
          } else {
            setMsg(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err, "error");
        });
    } else {
      setMsg("Please upload your required image");
    }
  };

  const Updatingphone = (e) => {
    e.preventDefault();
    if (mobilesaver != "") {
      const payload = new FormData();
      payload.append("mobilesaver", mobilesaver);
      payload.append("b_id", branch?.id);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL_NEW}/api/addmobilesaver`,
          payload,
          {
            headers: {
              "Content-type": "multipart/form-data",
              Authorization: process.env.REACT_APP_AUTH_KEY,
            },
          }
        )
        .then((res) => {
          let status = res.data.status;
          if (status === "success") {
            setSelectedImage1("");
            let FileInput = document.getElementById("hiddenFileInput1");
            FileInput.value = "";
            // setMsg("Image uploaded sucessfully");
            toastmsg(res.data.message, toast.POSITION.TOP_CENTER, 2000);
            setMobilesaver("");
            MobileImage();
          } else {
            setMsg(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err, "error");
        });
    } else {
      setMsg("Please upload your required image");
    }
  };

  const handleChange = (event) => {
    setBranch({ ...branch, id: event.target.value });
  };

  const Dropdown = ({ id, onChange }) => {
    let options = [];
    //console.log(branch,'list')

    return (
      <div className=" deptSelect">
        <label>
          {
            <select
              value={branch.id != "" && branch.id}
              className="form-control form_drop drops dept_selct select_rspo"
              onChange={onChange}
            >
              {branch.list?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          }
        </label>
      </div>
    );
  };

  // }
  return (
    <React.Fragment>
      <LoadingOverlay
        active={loading}
        spinner={true}
        text="Loading.."
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1100,
          }),
        }}
      >
        <div className="mb-4">
          <h3 className="page-title">Screen saver</h3>
        </div>
        <div className="d-flex justify-content-start mb-2">
          <Dropdown id="branch" onChange={handleChange} />
        </div>
        <div className="page-header"></div>
        <div className="row">
          <div className="col-md-6">
            {/* {!selectedImage ? <img className="device_icons" src={screensaver} /> : ""} */}
            <div>
              <>
                <input
                  onChange={(e) => imageChange(e)}
                  style={{ display: "none" }}
                  name="saver"
                  id="hiddenFileInput"
                  accept="image/*"
                  type="file"
                  ref={inputFileRef}
                />
              </>

              <div style={styles.preview}>
                <img
                  src={selectedImage != null ? selectedImage : screensaver}
                  style={styles.image}
                  alt="Thumb"
                />
              </div>

              <form onSubmit={Updating}>
                <div className="saver_buttons  mt-4">
                  <Button className="upload_btn" onClick={clickInput}>
                    Upload
                  </Button>
                  <Button className="upload_btn" type="submit">
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>

          <div className="col-md-6 smalldevice">
            {/* {!selectedImage1 ? <img src={mobileview} className="device_icons" /> : ""} */}

            <div style={styles.container}>
              <input
                style={{ display: "none" }}
                name="phonesaver"
                accept="image/*"
                type="file"
                id="hiddenFileInput1"
                onChange={(e) => imagePhone(e)}
                ref={inputFileRef}
              />
              <div style={styles.preview}>
                <img
                  src={selectedImage1 != null ? selectedImage1 : mobileview}
                  style={{
                    maxWidth: "32%",
                    height: "245px",
                    objectFit: "cover",
                  }}
                  alt="Thumb"
                />
              </div>
              <form onSubmit={Updatingphone}>
                <div className="saver_buttons  mt-4">
                  <Button className="upload_btn" onClick={clickInput1}>
                    Upload
                  </Button>
                  <Button className="upload_btn" type="submit">
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </React.Fragment>
  );
};
export default Saver;

const styles = {
  // container: {
  //   display: "flex",
  //   flexDirection: "column",
  //   justifyContent: "center",
  //   alignItems: "center",
  // },

  preview: {
    display: "flex",
    justifyContent: "center",
  },
  image: { maxWidth: "80%", maxHeight: 320 },
  image2: { maxWidth: "20%", maxHeight: 500, ObjectFit: "cover" },
  delete: {
    cursor: "pointer",
    padding: 15,
    background: "red",
    color: "white",
    border: "none",
  },
};
