import React, { Component } from "react";
import Config from "../../../config";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ReactSpeedometer from "react-d3-speedometer";
import "../Dashboard/progressBar.css";
import "../../dashboard/recharts.css";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import ThumbsUp from "../../../assets/images/dashboard/bi_hand-thumbs-up-fill.png";
import ThumbsDown from "../../../assets/images/dashboard/bi_hand-thumbs-down-fill.png";
import ThumbsSide from "../../../assets/images/dashboard/bi_hand-thumbs-side-fill.png";
import DataTable from "react-data-table-component";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { FaFileExcel } from "react-icons/fa";
import { Dropdown, Form } from "react-bootstrap";
import exportFromJSON from "export-from-json";
import { saveAs } from "file-saver";
import axios from "axios";

export class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departments: [],
      table: [],
      search: "",
    };
    this.value = this.props.data;
    this.branchId = this.props.branchId;
    this.config = new Config();
    this.tableData = this.tableData.bind(this);
    this.excelDownload = this.excelDownload.bind(this);
    this.pdfDownload = this.pdfDownload.bind(this);
  }

  tableData() {
    this.setState({ table: [] });
    let search = this.state.search == "" ? 0.001 : this.state.search;
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/dashboardfordisplay/0/${this.props.fromdate}/${this.props.todate}/${search}/${this.props.type}/${this.props.branchId}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        let response = res.data.data;
        console.log(response, "response");
        this.setState({ table: response });
      });
  }

  componentDidMount() {
    this.tableData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.search !== this.state.search) {
      this.tableData();
    }
  }

  excelDownload(data) {
    exportFromJSON({
      data: data,
      fileName: `${new Date()}.xls`,
      exportType: exportFromJSON.types.xls,
    });
  }

  pdfDownload(number) {
    let search = this.state.search == "" ? 0.001 : this.state.search;
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/dashboardpdf/0/${this.props.fromdate}/${this.props.todate}/${number}/${search}/${this.branchId}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_AUTH_KEY,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }

        const blob = new Blob([response.data], { type: "application/pdf" });

        // Create a temporary URL for the blob
        const url = URL.createObjectURL(blob);

        // Create a hidden link element
        const link = document.createElement("a");
        link.href = url;
        link.download = `Userdetails-${Date.now()}.pdf`;

        // Append the link to the body and simulate a click
        document.body.appendChild(link);
        link.click();

        // Clean up the temporary URL and remove the link element
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    // {
    //   headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
    // })
    // .then((res) => {
    //   saveAs(`${process.env.REACT_APP_BASE_URL_NEW}/${res.data.result.fileName}`, `Userdetails-${Date.now()}.pdf`);
    // });
  }

  render() {
    console.table(this.branchId, "value");
    const form = {
      position: "relative",
      marginRight: "13px",
      marginBottom: "0  !important",
    };
    const searchicon = {
      position: "absolute",
      right: "10px",
      bottom: "10px",
      color: "#18A7E2",
    };
    const columns = [
      {
        name: "User Name",
        selector: (row) => row.user_name,
        center: true,
        sortable: true,
      },
      {
        name: "Date",
        selector: (row) => row.created_date,
        center: true,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        center: true,
        sortable: true,
      },
      {
        name: "Mobile Number",
        selector: (row) => row.phone_number,
        center: true,
        sortable: true,
      },
      {
        name: "Department",
        selector: (row) => row.department_english,
        center: true,
        sortable: true,
      },
      {
        name: "Satisfactory Level",
        selector: (row) => row.answer,
        center: true,
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row) => row.answer == "sad",
            style: {
              backgroundColor: "#FF6D63",
              color: "white",
              "&:hover": {
                cursor: "pointer",
              },
            },
          },
          {
            when: (row) => row.answer == "neutral",
            style: {
              backgroundColor: "#F7CF56",
              color: "white",
              "&:hover": {
                cursor: "pointer",
              },
            },
          },
          {
            when: (row) => row.answer == "happy",
            style: {
              backgroundColor: "#8EE41E ",
              color: "white",
              "&:hover": {
                cursor: "not-allowed",
              },
            },
          },
          // You can also pass a callback to style for additional customization
          {
            when: (row) => row.answer < 600,
            style: (row) => ({
              backgroundColor: row.isSpecial ? "pink" : "inerit",
            }),
          },
        ],
      },
    ];

    const customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "18px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    let data1 = this.props.data1;
    let newArr = [];
    newArr.push(data1);

    // Object.keys(data1).map((key, i) => {
    //   newArr.push(data1[key]);
    // });

    let newcheck = [];
    newArr.map((val) => {
      let sad, neutral, happy;
      sad = Math.round((val.sad / (val.sad + val.neutral + val.happy)) * 100);
      neutral = Math.round(
        (val.neutral / (val.sad + val.neutral + val.happy)) * 100
      );
      happy = Math.round(
        (val.happy / (val.sad + val.neutral + val.happy)) * 100
      );
      newcheck.push({
        name: val.departmentname,
        Promoters: happy,
        Detractors: sad,
        Passives: neutral,
      });
    });

    // let outputData =  newArr.map((val,i) => {
    //     let resultArr = []
    //     Bardata.map((data,index) => {
    //         if(index === i){
    //             data.x=Math.round((val.happy/(val.sad+val.neutral+val.happy))*100);
    //             data.y=Math.round((val.sad/(val.sad+val.neutral+val.happy))*100);
    //             data.z=Math.round((val.neutral/(val.sad+val.neutral+val.happy))*100);
    //             resultArr.push(data)
    //         }

    //     })
    //     console.log(Bardata,'resultArr')

    // })
    // console.log(Bardata,"hhfdfhb")

    console.log(this.value, "value");
    return (
      <div className="question-tabs mt-5">
        <div> {this.props.data.question}</div>
        <div> {this.props.data.arabic}</div>
        <div className="row mt-3">
          <div className="col-md-7 grid-margin stretch-card">
            <div className="card">
              <div className="card-body d-flex">
                <div className="w-100 circle_card">
                  <CircularProgressbar
                    value="100"
                    text={`${this.props.data.happyval}%`}
                    strokeWidth={13}
                    className="circle1"
                  />
                  <div className="marg-align">
                    <img src={ThumbsUp} alt="thumbs-up" /> Promoters
                  </div>
                </div>
                <div className="w-100 circle_card">
                  <CircularProgressbar
                    value="100"
                    text={`${this.props.data.neutralval}%`}
                    strokeWidth={13}
                    className="circle2"
                  />
                  <div className="marg-align">
                    <img src={ThumbsSide} alt="thumbs-side" /> Passives
                  </div>
                </div>
                <div className="w-100 circle_card">
                  <CircularProgressbar
                    value="100"
                    text={`${this.props.data.sadval}%`}
                    strokeWidth={13}
                    className="circle3"
                  />
                  <div className="marg-align">
                    <img src={ThumbsDown} alt="thumbs-down" /> Detractors
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 grid-margin stretch-card">
            <div className="card">
              <div className="card-body row align-items-center">
                <div className="col-sm-3 fontColor">NPS Score</div>
                <div className="col-sm-9 score-align">
                  <ReactSpeedometer
                    segments={3}
                    value={this.props.data.happyval - this.props.data.sadval}
                    minValue={-100}
                    maxValue={100}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <BarChart width={800} height={500} data={newcheck}>
                  <CartesianGrid />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  {/* <Legend /> */}
                  <Bar dataKey="Promoters" stackId="a" fill="#8EE41E" />
                  <Bar dataKey="Detractors" stackId="a" fill="#FF6D63" />
                  <Bar dataKey="Passives" stackId="a" fill="#F7CF56" />
                </BarChart>
              </div>
            </div>
          </div>
        </div>

        <div className="row dash-align mt-5">
          <div className="col-md-7"></div>
          <div className="drop col-md-3">
            <Form>
              <Form.Group style={form} className=" form_head ">
                <Form.Control
                  className="form_drop "
                  value={this.state.search}
                  onChange={(e) => this.setState({ search: e.target.value })}
                />
                <i
                  style={searchicon}
                  className="fa fa-search search_icon"
                  aria-hidden="true"
                ></i>
              </Form.Group>
            </Form>
          </div>
          <div className="col-md-2">
            <Dropdown>
              <Dropdown.Toggle
                className="export"
                variant="success"
                id="dropdown-basic"
              >
                Export
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => this.pdfDownload(this.props.type)}
                >
                  {" "}
                  <BsFillFileEarmarkPdfFill className="pdf_icon" />
                  PDF
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => this.excelDownload(this.state.table)}
                >
                  <FaFileExcel className="excel_icon" />
                  EXCEL
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card mt-3">
              <div className="card-body">
                <div>
                  <DataTable
                    columns={columns}
                    data={this.state.table}
                    pagination
                    print="true"
                    fixedHeader
                    theme="solarized"
                    customStyles={customStyles}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Questions;
