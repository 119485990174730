import React, { Component, useEffect, useState } from "react";
import "../login/admin_login.css";
import Cancel from "../../assets/images/icons/cancel.svg";
import Submit from "../../assets/images/icons/submit.svg";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { toastmsg } from "../../Helper";
import Recaptcha from "../forgotpassword/recaptcha.js";
const Password = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [pwType, setPwType] = useState("password");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [msg, setMsg] = useState("");

  let history = useHistory();
  const [captcha, setCaptcha] = useState("");
  const [err, setErr] = useState(false);
  const email = history.location.pathname.split("/")[2];
  const token = history.location.pathname.split("/")[3];
  const type = history.location.pathname.split("/")[4];

  const onSubmit = (data) => {
    if (data.newPassword == data.confirmPassword) {
      let fetch = async () => {
        await axios
          .post(
            `${process.env.REACT_APP_BASE_URL_NEW}/api/adminresetpass/${email}/${token}/${type}`,
            { password: data.newPassword },
            {
              headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
            }
          )
          .then((res) => {
            console.log(res, "checking response");

            if (res.data.status == "sucess") {
              toastmsg("Password changed successfully", toast.POSITION.TOP_CENTER, 2000);
              setInterval(() => {
                window.location.replace("/");
              }, 2000);
              // history.push("/");
            }
          });
      };
      if (captcha) {
        fetch();
      } else {
        setErr(true);
      }
    } else {
      setMsg("newPassword and confirmPassword must be same");
    }
  };
  const handleShowPass = () => {
    //  console.log('click')
    let pwd = document.getElementById("passwordField");
    if (pwd.type === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };
  const handlePass = () => {
    let pwd1 = document.getElementById("passwordField1");
    if (pwd1.type === "password") {
      setPwType("text");
    } else {
      setPwType("password");
    }
  };

  function onChange(value) {
    let captchaval = value;
    setCaptcha(captchaval);
    setErr(false);
    return captchaval;
  }

  return (
    <>
      <ToastContainer closeButton={false} hideProgressBar={true} />
      <div className="row  h-100">
        <div className="offset-lg-2 col-lg-8">
          <div className="card_respo  h-100  d-flex align-items-center ">
            <div className="card card1">
              <div className="card card2">
                <div className="card card3">
                  <div className=" mt-4 text-center">
                    <h4 className="header">Admin Password Change</h4>
                  </div>
                  <br />
                  <div className="body">
                    <form className="forms" autoComplete="off">
                      <div className="row offset-md-1 align-items-center">
                        <div className="col-md-4">
                          <label className="lable_title">New Password</label>
                        </div>
                        <div className="col-md-7 pass_input">
                          <input id="passwordField" className="brdr_clr" type={passwordType} {...register("newPassword", { required: true })} />
                          <span type="button" onClick={handleShowPass}>
                            {passwordType === "password" ? <FaEyeSlash className="fa_eye" /> : <FaEye className="fa_eye" />}
                          </span>
                          {errors.newPassword && errors.newPassword.type === "required" && <p className="error">Field cannot be empty</p>}
                        </div>
                      </div>
                      <br></br>
                      <div className="row offset-md-1 align-items-center">
                        <div className="col-md-4">
                          <label className="lable_title">Confirm Password</label>
                        </div>
                        <div className="col-md-7 pass_input">
                          <input id="passwordField1" className="brdr_clr" type={pwType} {...register("confirmPassword", { required: true })} />
                          <span type="button" onClick={handlePass}>
                            {pwType === "password" ? <FaEyeSlash className="fa_eye" /> : <FaEye className="fa_eye" />}
                          </span>
                          {errors.newPassword && errors.newPassword.type === "required" && <p className="error">Field cannot be empty</p>}
                          <p className="error">{msg} </p>
                        </div>
                        {/* <div className='col-md-9'>
                          <p className='error'>{msg} </p>
                        </div> */}
                        <div className="d-flex justify-content-center">
                          <Recaptcha onChange={onChange}></Recaptcha>
                        </div>
                        <p className={err ? "errormessage d-flex justify-content-center " : "errormessage1 "}>Please complete the reCAPTCHA. Verify that you are not a robot.</p>
                      </div>
                      {/* <div className='row offset-md-3'>
                        <div className='col-md-9'>
                          <p className='error'>{msg} </p>
                        </div>
                      </div> */}
                    </form>
                    <div className="row pt-4 pb-4">
                      <div className="col-sm-6 col-6 text-center">
                        <img src={Cancel} alt="cancel" onClick={() => history.push("/")} />
                      </div>

                      <div className="col-sm-6 col-6 text-center">
                        <img src={Submit} alt="submit" onClick={handleSubmit(onSubmit)} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Password;
