
// const TOKEN_KEY='admin_data';

// export const login=(data) => {
//     localStorage.setItem(TOKEN_KEY, 1);
// }

// export const logout=() => {
//     localStorage.removeItem(TOKEN_KEY);
// }

// export const isLogin=() => {
//     if (localStorage.getItem(TOKEN_KEY)) {
//         return true;
//     }
//     return false;
// }

// routeIndex.js


const TOKEN_KEY = 'admin_data';
const USER_TYPE_KEY = 'user_type';

export const login = (data) => {
  localStorage.setItem(TOKEN_KEY, 1);
  localStorage.setItem(USER_TYPE_KEY, data.type); // Set user type when logging in
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_TYPE_KEY);
};

export const isLogin = () => {
  return localStorage.getItem(TOKEN_KEY) ? true : false;
};

export const getUserType = () => {
  return localStorage.getItem(USER_TYPE_KEY);
};

