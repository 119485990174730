import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { Component, useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import delete1 from "../../../assets/images/icons/delete.png";
import edit from "../../../assets/images/icons/edit.png";
import ReactPaginate from "react-paginate";
import axios from "axios";
import "../../departments/department.css";
import { resetServerContext } from "react-beautiful-dnd";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import download from "../../../assets/images/icons/Vectordownload.png";
import { toastmsg } from "../../../Helper";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import LoadingOverlay from "react-loading-overlay";

const Table = () => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [page, setpage] = useState(0);
  const [department, setDepartment] = useState("");
  const [pagecount, setPagecount] = useState(0);
  const [Adddept, setAddDept] = useState({ english: "", arabic: "" });
  const [did, setDid] = useState("");
  const [change, setChange] = useState(0);
  const [loading, setLoading] = useState(true);
  const [branch, setBranch] = useState({ list: [], id: "0" });
  const [dimensions, setDimensions] = useState({
    latitude: "",
    longitude: "",
  });

  let limit = 5;
  let subAdminId = localStorage.getItem("subadmin_id");
  let branchId = localStorage.getItem("branch_id");

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/subbranchlist/${subAdminId}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        let newlist = res.data.data;
        let options = [];
        if (res.data.data) {
          if (newlist?.length > 0) {
            for (let i = 0; i < newlist?.length; i = i + 1) {
              // console.log(newlist[i], "item");
              let item = {
                value: newlist[i].b_id,
                label: newlist[i].branch_name,
              };
              options.push(item);
            }
          } else {
            options = [];
          }
          setBranch({ id: res.data.data[0].b_id, list: options });
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);

  useEffect(() => {
    fetch();
    geo();
  }, [page, department, change, branch.id]);

  const fetch = async () => {
    setLoading(true);
    let searchText = department === "" ? 0.001 : department;
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/departments/${page}/${limit}/${subAdminId}/${branch.id}/${searchText}`,
        { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } }
      )
      .then((res) => {
        let responseData = res.data.data;
        let total = res.data.totalPages;
        setData(responseData);
        setPagecount(total);
        setLoading(false);
      });
  };

  const geo = () => {
    // navigator.geolocation.getCurrentPosition((position) => {
    //   dimensions.latitude = position.coords.latitude;
    //   dimensions.longitude = position.coords.longitude;
    //   setDimensions({ ...dimensions, latitude: position.coords.latitude, longitude: position.coords.longitude });
    // });

    branch.id != 0 &&
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL_NEW}/api/getbranchtoedit/${branch.id}`,
          {
            headers: {
              Authorization: `${process.env.REACT_APP_AUTH_KEY}`,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            setDimensions({
              ...dimensions,
              latitude: res.data.data[0].lat,
              longitude: res.data.data[0].long,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };

  const Dropdown = ({ id, onChange }) => {
    //console.log(branch,'list')
    return (
      <div className="mr-3 ">
        <label>
          {id == "branch" ? (
            <select
              value={branch.id != "" && branch.id}
              className="form-control form_drop drops dept_selct select_rspo"
              onChange={onChange}
            >
              {branch.list?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          ) : (
            <select
              value={department.id != "" && department.id}
              className="form-control form_drop drops dept_selct select_rspo"
              onChange={onChange}
            >
              {department.list?.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </select>
          )}
        </label>
      </div>
    );
  };

  const handleChange = (event) => {
    setBranch({ ...branch, id: event.target.value });
    setpage(0);
  };

  const handlePageClick = (event) => {
    setpage(event.selected);
  };

  const handleShow = (id, eng, arab) => {
    if (id >= 1) {
      setShow(true);
      setAddDept({ english: eng, arabic: arab });
      setDid(id);
    } else {
      setShow(true);
    }
  };

  const AddDepartment = (e) => {
    setAddDept({ ...Adddept, [e.target.name]: e.target.value });
  };

  const isArabic = (text) => {
    const arabicRegex = /[\u0600-\u06FF\u0750-\u077F]/;
    return arabicRegex.test(text);
  };

  const handleArabicInputChange = (e) => {
    const inputValue = e.target.value;
    if (isArabic(inputValue) || inputValue === "") {
      // Update state only if the input is Arabic or empty
      setAddDept({ ...Adddept, arabic: inputValue });
    }
  };

  const handleClose = () => {
    setAddDept({ english: "", arabic: "" });
    setDid("");
    setShow(false);
  };

  const handleClose1 = () => {
    if (did >= 1) {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL_NEW}/api/editdepartment`,
          {
            d_id: did,
            deptname_english: Adddept.english,
            deptname_arabic: Adddept.arabic,
          },
          { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } }
        )
        .then((res) => {
          if ((res.data.status = "success")) {
            toastmsg(
              "Department updated Succesfully",
              toast.POSITION.TOP_CENTER,
              3000
            );
            setAddDept({ english: "", arabic: "" });
            setDid("");
            setShow(false);
            setLoading(false);
            fetch();
          } else {
            toastmsg(res.data.message, toast.POSITION.TOP_CENTER, 2000);
            setShow(false);
            setLoading(false);
          }
        });
    } else {
      if (Adddept.english != "") {
        setLoading(true);
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL_NEW}/api/adddepartment`,
            {
              deptname_english: Adddept.english,
              deptname_arabic: Adddept.arabic,
              admin_id: subAdminId,
              branch_id: branch.id,
            },
            { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } }
          )
          .then((res) => {
            if (res.data.status == "success") {
              toastmsg(
                "Department added Succesfully",
                toast.POSITION.TOP_CENTER,
                3000
              );
              setChange(change + 1);
              setShow(false);
              setAddDept({ english: "", arabic: "" });
              setDid("");
              fetch();
              setLoading(false);
            } else {
              toastmsg(res.data.message, toast.POSITION.TOP_CENTER, 2000);
              setChange(change + 1);
              setLoading(false);
            }
          });
      } else {
        // alert('Please add both English and Arabic Questions')
        toastmsg(
          "Please add Department in English ",
          toast.POSITION.TOP_CENTER,
          3000
        );
      }
    }
  };

  const QrDownload = (d_id, b_id, a_id, department) => {
    let branch_name = "";
    branch.list.map((item, index) => {
      if (b_id == item.value) {
        branch_name = item.label;
      }
    });
    let canvas = document.getElementById(`qrCode${d_id}`);
    let image = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `${department}_${branch_name}.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Do you want to delete?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#000000",
      // denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoading(true);
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL_NEW}/api/deletedepartment`,
            { d_id: id, admin_id: subAdminId, branch_id: branch.id },
            { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } }
          )

          .then((res) => {
            toastmsg(res.data.message, toast.POSITION.TOP_CENTER, 2000);
            setChange(change + 1);
            setLoading(false);
          })
          .catch((err) => {
            toastmsg(err.data.message, toast.POSITION.TOP_CENTER, 2000);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner={true}
        text="Loading.."
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1100,
          }),
        }}
      >
        <div>
          <div className="mb-4 d-flex justify-content-between">
            <h3 className="page-title">Departments </h3>
            <div className="d-flex justify-content-around">
              <Dropdown
                id="branch"
                //value={branch.list}
                onChange={handleChange}
              />
              <Form>
                <Form.Group className="mr-3 form_head ">
                  <Form.Control
                    className=" form_drop"
                    value={department}
                    onChange={(e) => setDepartment(e.target.value)}
                    placeholder="Search Department"
                  />
                  <i
                    className="fa fa-search search_icon"
                    aria-hidden="true"
                  ></i>
                </Form.Group>
              </Form>
              <div className="deptbtn_respo">
                <Button className="quat_btn" onClick={handleShow}>
                  Add Department
                </Button>
              </div>
            </div>
          </div>
          <table className="table table-responsive table-bordered table_style table_ respo table_respo">
            <thead>
              <tr>
                <th>S.NO</th>
                <th>Department in English</th>
                <th>Department in Arabic</th>
                <th>QR code</th>
                {/* <th>Options</th> */}
              </tr>
            </thead>
            <tbody>
              {data ? (
                data?.map((values, index) => (
                  <tr key={index}>
                    <td className="table_height">{page * 5 + index + 1}</td>
                    <td className="table_height">
                      {values.department_english}
                    </td>
                    <td className="table_height">{values.department_arabic}</td>
                    <td className="table_height">
                      <QRCodeCanvas
                        style={{ display: "none" }}
                        id={`qrCode${values.d_id}`}
                        value={`http://gradeusglobal.com/home/?adminId=${subAdminId}&branchId=${values.branch_id}&departmentId=${values.d_id}&&lats=${dimensions.latitude}&&long=${dimensions.longitude}`}
                        includeMargin={true}
                        size={200}
                        bgColor={"#ffffff"}
                        level={"H"}
                      />
                      <QRCodeCanvas
                        style={{ cursor: "pointer" }}
                        id={`qrCode${values.d_id}`}
                        value={`http://gradeusglobal.com/home/?adminId=${values.admin_id}&branchId=${values.branch_id}&departmentId=${values.d_id}&lats=${dimensions.latitude}&long=${dimensions.longitude}`}
                        includeMargin={true}
                        size={100}
                        bgColor={"#ffffff"}
                        level={"H"}
                        // onClick={() => QrDownload(values.d_id, values.branch_id, values.admin_id, values.department_english)}
                      />
                      <p
                        title="Click to download"
                        style={{ cursor: "pointer", fontWeight: "bold" }}
                        onClick={() =>
                          QrDownload(
                            values.d_id,
                            values.branch_id,
                            values.admin_id,
                            values.department_english
                          )
                        }
                      >
                        <img
                          src={download}
                          className="td_img"
                          alt="Qr download"
                        />
                        Download
                      </p>
                    </td>
                    {/* <td className="table_height">
                  <img
                    className="td_img"
                    onClick={() =>
                      handleShow(
                        values.d_id,
                        values.department_english,
                        values.department_arabic
                      )
                    }
                    src={edit}
                    alt="view"
                  />
                  <img
                    className="ml-3 td_img"
                    onClick={() => handleDelete(values.d_id)}
                    src={delete1}
                    alt="delete"
                  />
                </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8}>No data found</td>
                </tr>
              )}
            </tbody>
          </table>
          <br />
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pagecount}
            forcePage={page}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />

          <Modal show={show} onHide={handleClose} animation={true}>
            <Modal.Header closeButton></Modal.Header>

            <Modal.Title className="modal_title">
              {did != "" ? "Edit Department" : "Add Department"}
            </Modal.Title>

            <Modal.Body>
              <Form autoComplete="off">
                <Form.Group>
                  <div className="row align-items-center p-4">
                    <div className="col-md-2">
                      <Form.Label>English</Form.Label>
                    </div>
                    <div className="col-md-10">
                      <Form.Control
                        className="brder_align"
                        name="english"
                        value={Adddept.english}
                        onChange={AddDepartment}
                      />
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="col-md-2">
                      <Form.Label>Arabic</Form.Label>
                    </div>
                    <div className="col-md-10">
                      <Form.Control
                        className="brder_align"
                        name="arabic"
                        value={Adddept.arabic}
                        onChange={handleArabicInputChange}
                        pattern="[\u0600-\u06FF\u0750-\u077F]*"
                        title="Please enter Arabic characters only"
                      />
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </Modal.Body>

            <Modal.Footer>
              <Button className="modal_btn" onClick={handleClose1}>
                {did != "" ? "Edit" : "Add"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </LoadingOverlay>
    </>
  );
};

export default Table;
