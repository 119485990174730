import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

function Recaptcha({ onChange }) {
  let recaptcha;

  if (window.location.hostname === "localhost") {
    recaptcha = (
      <ReCAPTCHA
        sitekey="6LedSP0pAAAAANCQwuVV-kO_ge10iQ3RxF1KjER0"
        onChange={onChange}
      />
    );
  }
  if (window.location.hostname === "bu183.krify.com") {
    recaptcha = (
      <ReCAPTCHA
        sitekey="6LedSP0pAAAAANCQwuVV-kO_ge10iQ3RxF1KjER0"
        onChange={onChange}
      />
    );
  }
  if (window.location.hostname === "admin.gradeusglobal.com") {
    recaptcha = (
      <ReCAPTCHA
        sitekey="6LedSP0pAAAAANCQwuVV-kO_ge10iQ3RxF1KjER0"
        onChange={onChange}
      />
    );
  }
  if (window.location.hostname === "gradeusglobal.com") {
    recaptcha = (
      <ReCAPTCHA
        sitekey="6LedSP0pAAAAANCQwuVV-kO_ge10iQ3RxF1KjER0"
        onChange={onChange}
      />
    );
  }

  return <>{recaptcha}</>;
}

export default Recaptcha;
