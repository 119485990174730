// import React from "react";
// import { Route, Redirect } from "react-router-dom";
// import { isLogin } from "./routeIndex";

// function PrivateRoute({ component: Component, ...rest }) {
//    return <Route {...rest} render={(props) => (isLogin() ? <Component {...props} /> : <Redirect to={{ pathname: "/", state: { from: props.location } }} />)} />;
// }

// export default PrivateRoute;

// PrivateRoute.js

// import React from "react";
// import { Route, Redirect } from "react-router-dom";
// import { isLogin, getUserType } from "./routeIndex";

// export const PrivateRoute = ({ component: Component, ...rest }) => {
//   const userType = getUserType();
//   return <Route {...rest} render={(props) => (isLogin()&&userType === "1" ? <Component {...props} /> : <Redirect to={{ pathname: "/", state: { from: props.location } }} />)} />;
// };

// export const SubPrivateRouting = ({ component: Component, ...rest }) => {
//    const userType = getUserType();
//   return <Route {...rest} render={(props) => (isLogin()&& userType=== "2" ? <Component {...props} /> : <Redirect to={{ pathname: "/", state: { from: props.location } }} />)} />;
// };

// PrivateRoute.js

// import React from "react";
// import { Route, Redirect } from "react-router-dom";

// export const PrivateRoute = ({ component: Component, ...rest }) => {
//   const isAuthenticated = localStorage.getItem("admin_data") !== "";
//   const userType = localStorage.getItem("user_type");

//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         isAuthenticated && userType === "1" ? (
//           <Component {...props} />
//         ) : (
//           <Redirect
//             to={{
//               pathname: isAuthenticated ? "/dashboard" : "/",
//               state: { from: props.location },
//             }}
//           />
//         )
//       }
//     />
//   );
// };

// export const SubPrivateRouting = ({ component: Component, ...rest }) => {
//   const isAuthenticated = localStorage.getItem("admin_data") !== "";
//   const userType = localStorage.getItem("user_type");
//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         isAuthenticated && userType === "2" ? (
//           <Component {...props} />
//         ) : (
//           <Redirect
//             to={{
//               pathname: isAuthenticated ? "/subDashboard" : "/",
//               state: { from: props.location },
//             }}
//           />
//         )
//       }
//     />
//   );
// };

import React from "react";
import { Route, Redirect } from "react-router-dom";

/**
 * Component that redirects to login if not authenticated, or to the intended
 * component if authenticated.
 * @param {Object} props The component's props
 * @param {React.ComponentType<any>} props.component The component to render if authenticated
 * @param {any} props.rest The rest of the props to pass to the Route component
 * @returns {React.ReactElement} The rendered component
 */
export const PrivateRoute  = ({ component: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem("admin_data") !== null;
  const userType = localStorage.getItem("user_type");

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && userType === "1" ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location.pathname },
            }}
          />
        )
      }
    />
  );
};


export const SubPrivateRouting = ({ component: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem("admin_data") !== "";
  const userType = localStorage.getItem("user_type");

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && userType === "2" ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location.pathname },
            }}
          />
        )
      }
    />
  );
};
