const notificationReducer=(state=0, action) => {

    switch (action.type) {
        case 'NOTIFICATION':
            return action.data;
        default:
            return state;
    }
    
}
export default notificationReducer;

