import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Logins from "../../assets/images/icons/login.svg";
import axios from "axios";
import thumbsUp from "../../assets/images/thumbs-up.png";
import thumbsGif from "../../assets/images/Thankyou.gif";
// import { hashHistory } from "react-router-dom";

function Verification() {
  let history = useHistory();
  const string = history.location.search;
  const email = string.split("/")[0].split("?")[1];
  const admin_Id = string.split("/")[1];
  const token = string.split("/")[2];
  // const history = createBrowserHistory();
  const [isVerified, setIsVerified] = useState(false);
  const [msg, setMsg] = useState("");
  // const email = history.location.pathname.split("/")[2];
  // const token = history.location.pathname.split("/")[3];
  // console.log(token, email, "email");

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/verifyemail/${
          email && email
        }/${admin_Id && admin_Id}/${token && token}`,
        {},
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        if (res.data.status == "success") {
          setIsVerified(true);
          setTimeout(() => {
            history.push("/");
          }, 4000);

          // history.push("/") gradeus_admin;
        } else {
          setMsg(res.data.message);
          const targetPath = "/";
          setTimeout(() => {
            history.push("/");
          }, 4000);
          // window.location.replace("/admin_login")
          // history.push("/");
        }
      });
  };

  const handleSubmit = () => {
    history.push("/");
  };

  return (
    <React.Fragment>
      <div className="row h-100">
        <div className="offset-lg-2 col-lg-8">
          <div className="card_respo   h-100 d-flex align-items-center">
            <div className="verify_card card1 ">
              <div className="verify_card card2">
                <div className="verify_card  card3 justify-content-center p-4">
                  {isVerified ? (
                    <>
                      <div className=" mt-3 text-center ">
                        <div className="">
                          <img
                            src={thumbsGif}
                            alt="thankyou "
                            className="gif surface_image"
                          />
                        </div>
                        <h4 className="email_header">
                          Successfully Verified Your Email Address{" "}
                        </h4>
                      </div>
                      <br />
                      {/* <div className="text-center p-4">Your email is successfully verified</div> */}
                    </>
                  ) : (
                    <>
                      <div className=" mt-4 text-center ">
                        <h4 className="email_header">
                          <img src={thumbsUp} width="50px" />{" "}
                          {msg == "Token not Matched" ? (
                            "Your Email already Verified"
                          ) : (
                            <>{msg && msg}</>
                          )}
                        </h4>
                      </div>
                      <br />
                      {/* <div className="text-center">
                        {msg == "Token not Matched" ? (
                          <>
                            {" "}
                            <button onClick={handleSubmit} className="go_to_button">
                              Go to Login
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </div> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Verification;
