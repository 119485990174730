import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { FaFileExcel } from "react-icons/fa";
import { IoCalendarSharp } from "react-icons/io5";
import ReactPaginate from "react-paginate";
import "jspdf-autotable";
import { Dropdown } from "react-bootstrap";
import { Button } from "react-bootstrap";
import exportFromJSON from "export-from-json";
import { saveAs } from "file-saver";
import eye from "../../../assets/images/icons/eye.png";
import { Modal, CloseButton } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import submitarrow from "../../../assets/images/icons/submitarrow.png";
import downloadbtn from "../../../assets/images/icons/download.png";
import Tooltip from "react-bootstrap/Tooltip";
import Overlay from "react-bootstrap/Overlay";
import "../Reviews/subreview.css";
import LoadingOverlay from "react-loading-overlay";

const SubReview = () => {
  let today = new Date();
  let date = (today.getDate() < 10 ? "0" : "") + today.getDate();
  let requiredDate =
    today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)) + "-" + date;
  const [dept, setDept] = useState([]);
  const [did, setDid] = useState(0);
  const [pageno, setPageNo] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pagecount, setPageCount] = useState(0);
  const [data, setData] = useState([]);
  const [reviewdata, setReviewdata] = useState([]);
  const [fromdate, setFromdate] = useState(0);
  const [todate, setTodate] = useState(0);
  const [calender, setCalender] = useState("");
  const [show, setShow] = useState(false);
  const [views, setViews] = useState([]);
  const [branch, setBranch] = useState({ list: [], id: "0" });
  const [department, setDepartment] = useState({ list: [], id: "0" });
  const [showTooltip, setShowTooltip] = useState(false);
  const [item, setItem] = useState(false);

  const a_id = localStorage.getItem("adminId");
  let subAdminId = localStorage.getItem("subadmin_id");
  let branchId = localStorage.getItem("branch_id");

  // const [selectedOption, setSelectedOption] = useState(null);
  let DID = !localStorage.getItem("review")
    ? did
    : localStorage.getItem("review");

  let limit = 10;

  const handlePageClick = (event) => {
    setPageNo(event.selected);
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/subbranchlist/${subAdminId}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        let newlist = res.data.data;
        let options = [];
        if (res.data.data) {
          if (newlist?.length > 0) {
            for (let i = 0; i < newlist?.length; i = i + 1) {
              let item = {
                value: newlist[i].b_id,
                label: newlist[i].branch_name,
              };
              options.push(item);
            }
          } else {
            options = [];
          }
          setBranch({ id: res.data.data[0].b_id, list: options });
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/department_list/${subAdminId}/${branch?.id}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        console.log(res.data, "depart");
        let newlist = res.data.data;
        let options = [];
        if (res.data.data) {
          if (newlist?.length > 0) {
            for (let i = 0; i < newlist?.length; i = i + 1) {
              let item = {
                value: newlist[i].d_id,
                label: newlist[i].department,
              };
              options.push(item);
            }
          } else {
            options = [];
          }
          setDepartment({ id: res.data.data[0].d_id, list: options });
          setLoading(false);
        } else {
          setDepartment({ id: 0, list: [] });
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, [branch?.id]);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await axios
        .get(
          `${process.env.REACT_APP_BASE_URL_NEW}/api/getreviewsforsubadmin/${pageno}/${limit}/${department.id}/${fromdate}/${todate}/${branch?.id}`,
          { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } }
        )
        .then((res) => {
          let data = res.data.data;
          let total = res.data.totalPages;
          setData(data);
          setPageCount(total);
          setLoading(false);
        });
      await axios
        .get(
          `${process.env.REACT_APP_BASE_URL_NEW}/api/getallreviews/${department.id}/${fromdate}/${todate}/excel/${branch?.id}`,
          { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } }
        )
        .then((res) => {
          let data = res.data.data;
          setReviewdata(data);
          setLoading(false);
        });
    };

    if(branch?.id != 0) {
      fetch();
    }
  }, [department.id, pageno, calender]);

  const onChangeHandler = (e) => {
    setDid(e.target.value);
    localStorage.setItem("review", e.target.value);
  };

  const handleClose = () => {
    setShow(false);
    setItem(false);
  };

  const handleShow = (QE, QA, A, E, U, P) => {
    // view.push({English:QE,Arabic:QA,Answer:A,Email:E,User:U,Number:P})
    setViews({
      English: QE,
      Arabic: QA,
      Answer: A,
      Email: E,
      User: U,
      Number: P,
    });
    // console.log(view,"view")
    setShow(true);
  };
  // console.log(views.English,"VIEWSSS")

  const changeFromDate = (e) => {
    if (e.target.value) {
      setFromdate(e.target.value + " 00:00:00");
    } else {
      setFromdate(0);
    }
  };

  const changeToDate = (e) => {
    if (e.target.value) {
      setTodate(e.target.value + " 23:59:59");
    } else {
      setTodate(0);
    }
  };

  const calenderSubmit = (e) => {
    if (fromdate !== 0 && todate !== 0) {
      setCalender(fromdate + todate);
    } else {
      setCalender(0);
    }
  };

  const download = () => {
    if (reviewdata?.length > 0) {
      exportFromJSON({
        data: reviewdata,
        fileName: `Reviews.xls`,
        exportType: exportFromJSON.types.xls,
      });
    }
  };

  const pdfDownload = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/getallreviews/${department.id}/${fromdate}/${todate}/pdf/${branch?.id}`,
        {
          headers: {
            Authorization: process.env.REACT_APP_AUTH_KEY,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }

        const blob = new Blob([response.data], { type: "application/pdf" });

        // Create a temporary URL for the blob
        const url = URL.createObjectURL(blob);

        // Create a hidden link element
        const link = document.createElement("a");
        link.href = url;
        link.download = "Reviews.pdf";

        // Append the link to the body and simulate a click
        document.body.appendChild(link);
        link.click();

        // Clean up the temporary URL and remove the link element
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleChange = (event) => {
    setBranch({ ...branch, id: event.target.value });
    setPageNo(0);
  };

  const Dropdown1 = ({ id, onChange }) => {
    return (
      <div style={{ margin: "7px 7px 0px 0px" }}>
        <label>
          {id == "branch" ? (
            <select
              value={branch.id != "" && branch.id}
              className=" form-control form_drop drops dept_selct select_rspo"
              onChange={onChange}
            >
              {branch.list?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          ) : (
            <select
              value={department.id != "" && department.id}
              className="form-control form_drop drops dept_selct select_rspo"
              onChange={onChange}
              style={{ width: "100px" }}
            >
              {department.list?.length == 0 ? (
                <option>No departments</option>
              ) : (
                <>
                  {department.list?.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </>
              )}
            </select>
          )}
        </label>
      </div>
    );
  };

  const handleChangeDepartment = (event) => {
    setDepartment({ ...department, id: event.target.value });
    setPageNo(0);
  };

  const handleTooltip = () => {
    setItem(true);
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner={true}
        text="Loading.."
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1100,
          }),
        }}
      >
        <div>
          <div>
            <div className="mb-2">
              <h3 className="page-title">Reviews </h3>
            </div>

            <div className="mb-4 d-md-flex   align-items-center justify-content-end">
              <div className=" d-md-flex ml-auto flex-wrap ">
                <div className="d-md-flex ">
                  <div className="d-flex ">
                    <input
                      type="date"
                      id="fromDate"
                      max={requiredDate}
                      onChange={changeFromDate}
                    />{" "}
                    <span className="picker_to mt-3">to</span>
                    <input
                      type="date"
                      id="toDate"
                      min={fromdate !== 0 ? fromdate.substr(0, 10) : ""}
                      max={requiredDate}
                      onChange={changeToDate}
                    />
                  </div>
                  <Button
                    className="calender_submit mx-2"
                    onClick={calenderSubmit}
                  >
                    Submit
                  </Button>
                </div>
                <Dropdown style={{ marginTop: "6px" }}>
                  <Dropdown.Toggle
                    className=""
                    variant="success"
                    id="dropdown-basic"
                  >
                    Export
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={data?.length > 0 ? pdfDownload : undefined}
                    >
                      <BsFillFileEarmarkPdfFill className="pdf_icon" />
                      PDF
                    </Dropdown.Item>
                    <Dropdown.Item onClick={download}>
                      <FaFileExcel className="excel_icon" />
                      EXCEL
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown1
                  id="branch"
                  //value={branch.list}
                  onChange={handleChange}
                />
                <Dropdown1
                  id="department"
                  //value={branch.list}
                  onChange={handleChangeDepartment}
                />
              </div>
            </div>
          </div>
          <div className="tableaboveDiv" style={{ overflow: "auto" }}>
            <table className="table table-responsive table-bordered  table_style">
              <tr>
                <th>S.No</th>
                <th>Question</th>
                <th>Answers</th>
                <th>Email Id</th>
                <th>UserName</th>
                <th>MobileNumber</th>
                {/* <th>Actions</th> */}
              </tr>
              <tbody>
                {data ? (
                  data.map((values, index) => (
                    <tr>
                      <td className="td nowrap_class">
                        {pageno * 10 + index + 1}
                      </td>
                      <td
                        className="td nowrap_class"
                        title={values.questionname_e}
                      >
                        <span>{values.questionname_e}</span>
                        <br />
                        <br />
                        <span>{values.questionname_a}</span>
                      </td>
                      <td className="td nowrap_class" title={values.answer}>
                        {values.answer}
                      </td>
                      <td className="td nowrap_class" title={values.email}>
                        {values.email}
                      </td>
                      <td className="td nowrap_class" title={values.user_name}>
                        {values.user_name}
                      </td>
                      <td
                        className="td nowrap_class"
                        title={values.phone_number}
                      >
                        {values.phone_number}
                      </td>
                      {/* <td>
                    <img
                      className="td_img"
                      onClick={() =>
                        handleShow(
                          values.questionname_e,
                          values.questionname_a,
                          values.answer,
                          values.email,
                          values.user_name,
                          values.phone_number
                        )
                      }
                      src={eye}
                      alt="view"
                    />
                  </td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8}>No data found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pagecount}
            forcePage={pageno}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />

          {/* <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Title className="modal_title"></Modal.Title>
        <Modal.Body>
          <div onClick={handleClose}>
            <CloseButton />
          </div>

          <div className="p-4">
            <label className="review_clr">English:</label>

            <div className=" review_brdr">{views.English}</div>

            <label className="mt-3 review_clr">Arabic:</label>
            <div className="review_brdr">{views.Arabic}</div>

            <label className="mt-3 review_clr">Answer:</label>
            <div className="review_brdr ">{views.Answer}</div>

            <label className=" mt-3 review_clr">Email:</label>
            <div className=" review_brdr">{views.Email}</div>

            <label className="mt-3 review_clr">UserName:</label>
            <div className="review_brdr">{views.User}</div>

            <label className=" mt-3 review_clr">PhoneNumber:</label>
            <div className="col-md-12 review_brdr">{views.Number}</div>
          </div>
        </Modal.Body>
      </Modal> */}
        </div>
      </LoadingOverlay>
    </>
  );
};

export default SubReview;
