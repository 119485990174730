import React, { Component, createElement } from "react";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { FaFileExcel } from "react-icons/fa";
import { Form } from "react-bootstrap";
import { PieChart } from "react-minimal-pie-chart";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import "./recharts.css";
import axios from "axios";
import Departments from "../alldepartments/all_departments";
import { Dropdown } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { IoCalendarSharp } from "react-icons/io5";
import DataTable from "react-data-table-component";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../alldepartments/react-tabs.css";
import exportFromJSON from "export-from-json";
import { saveAs } from "file-saver";
import ReactPaginate from "react-paginate";
import { Swiper, SwiperSlide } from "swiper/react";
import LoadingOverlay from "react-loading-overlay";
import SwiperCore, { Navigation, Pagination, Autoplay, Virtual } from "swiper";

import "swiper/swiper-bundle.css";
import "./recharts.css";
SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);
export class Dashboard extends Component {
  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      loading: true,
      table: [],
      userdata: [],
      fromdate: 0,
      todate: 0,
      calender: "",
      departments: [],
      did: 1,
      a_id: localStorage.getItem("adminId"),
      DID:
        localStorage.getItem("dashboard") == null
          ? 1
          : localStorage.getItem("dashboard"),
      search: "",
      type: 0,
      branchId: "",
      questions: {},
      startDate: new Date(),
      visitSaleData: {},
      visitSaleOptions: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                display: false,
                min: 0,
                stepSize: 20,
                max: 80,
              },
              gridLines: {
                drawBorder: false,
                color: "rgba(235,237,242,1)",
                zeroLineColor: "rgba(235,237,242,1)",
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false,
                color: "rgba(0,0,0,1)",
                zeroLineColor: "rgba(235,237,242,1)",
              },
              ticks: {
                padding: 20,
                fontColor: "#9c9fa6",
                autoSkip: true,
              },
              categoryPercentage: 0.5,
              barPercentage: 0.5,
            },
          ],
        },
        legend: {
          display: false,
        },
        elements: {
          point: {
            radius: 0,
          },
        },
      },
      dataMock: [],
      Bardata: [],
      nps1: "",
      nps2: "",
      nps3: "",
      trafficOptions: {
        responsive: true,
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        legend: false,
      },
      adminId:
        localStorage.getItem("userdata") == null
          ? 0
          : localStorage.getItem("userdata"),
      branchValue: 0,
      branchList: [],
      departmentValue: 0,
      departmentList: [],
      graphPageno: 0,
      pagecountGraph: 0,
    };

    this.setDepartmentId = this.setDepartmentId.bind(this);
    this.changeFromDate = this.changeFromDate.bind(this);
    this.changeToDate = this.changeToDate.bind(this);
    this.calenderSubmit = this.calenderSubmit.bind(this);
    this.getDepartment = this.getDepartment.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.excelDownload = this.excelDownload.bind(this);
    this.pdfDownload = this.pdfDownload.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDepartment = this.handleChangeDepartment.bind(this);
    this.handlePageClickGraph = this.handlePageClickGraph.bind(this);
  }

  departmentListFunc(id) {
    this.setState({ departmentList: [], departmentValue: 0 });
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/department_list/${this.state.a_id}/${id}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        let newlist = res.data.data;
        let options = [];
        if (res.data.data) {
          if (newlist?.length > 0) {
            for (let i = 0; i < newlist?.length; i = i + 1) {
              let item = {
                value: newlist[i].d_id,
                label: newlist[i].department,
              };
              options.push(item);
            }
            options.push({ value: "AllDepartments", label: "All Departments" });
          }
          //setDepartment({id:res.data.data[0].d_id,list:options})
          this.setState({ loading: false });
          this.setState({ departmentList: options });
          this.setState({ departmentValue: res.data.data[0].d_id });
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  changeFromDate(e) {
    if (e.target.value) {
      this.setState({ fromdate: e.target.value + " 00:00:00" });
    } else {
      this.setState({ fromdate: "0" });
    }
  }

  changeToDate(e) {
    if (e.target.value) {
      this.setState({ todate: e.target.value + " 00:00:00" });
    } else {
      this.setState({ todate: "0" });
    }
  }

  calenderSubmit(e) {
    if (this.state.fromdate !== 0 && this.state.todate !== 0) {
      this.setState({ calender: this.state.fromdate + this.state.todate });
    } else {
      this.setState({ calender: 0 });
    }
  }

  handlePageClickGraph = (e) => {
    const selected = e.selected;
    this.setState({ graphPageno: selected });
    this.setState({ type: 0 });
  };

  getDepartment(departmentId) {
    this.setState({ questions: "" });

    axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL_NEW
        }/api/dashboardbydepartment/${departmentId}/${this.state.fromdate}/${
          this.state.todate
        }/${this.state.graphPageno}/${3}/${this.state.branchValue}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        if (res.data.status == "success") {
          let response = res.data.data;
          this.setState({ pagecountGraph: res.data?.totalPages });
          if (response) {
            let Bardata2 = [];
            let dataMock2 = [];
            Object.keys(response).map((key, index) => {
              let dataMock1 = [
                { title: "DETRACTORS", value: "", count: "", color: "#FF6D63" },
                { title: "PROMOTERS", value: "", count: "", color: "#8EE41E" },
                { title: "PASSIVES", value: "", count: "", color: "#F7CF56" },
              ];
              // let some = `dataMock${index+1}`
              dataMock1[0].count = response[key].sad;
              dataMock1[1].count = response[key].happy;
              dataMock1[2].count = response[key].neutral;
              let totalCount =
                dataMock1[0].count + dataMock1[1].count + dataMock1[2].count;

              dataMock1[0].value = Math.round(
                (dataMock1[0].count / totalCount) * 100
              );
              dataMock1[1].value = Math.round(
                (dataMock1[1].count / totalCount) * 100
              );
              dataMock1[2].value = Math.round(
                (dataMock1[2].count / totalCount) * 100
              );
              dataMock2.push({ dataMock1 });
              Bardata2.push({
                name: response[key].question,
                Promoters: dataMock1[1].value,
                Detractors: dataMock1[0].value,
                Passives: dataMock1[2].value,
              });
            });
            this.setState({ dataMock: dataMock2 });
            this.setState({ Bardata: Bardata2 });
          }
          this.setState({ questions: response });
          this.setState({ loading: false });
        } else {
          this.setState({ questions: "" });
          this.setState({ loading: false });
        }
      });
  }

  getTableData(Departmentid, type) {
    let search = this.state.search == "" ? 0.001 : this.state.search;
    this.setState({ table: [] });
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/dashboardfordisplay/${
          Departmentid == "AllDepartments" ? 0 : Departmentid
        }/${this.state.fromdate}/${this.state.todate}/${search}/${
          3 * this.state.graphPageno + type
        }/${this.state.branchValue && this.state.branchValue}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        if (res.data.status == "success") {
          this.setState({ table: res.data.data });
          this.setState({ loading: false });
        }else {
          this.setState({ loading: false });
        }
      });
  }

  getUserData(Departmentid) {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/department/users/${
          Departmentid == "AllDepartments" ? 0 : Departmentid
        }/${this.state.fromdate}/${this.state.todate}/1/${
          this.state.branchValue
        }`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        if (res.data.status == "sucess") {
          this.setState({ userdata: res.data.data });
          this.setState({ loading: false });
        }else {
          this.setState({ loading: false });
        }
      });
  }

  componentDidMount() {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/branch_list/${this.state.a_id}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )

      .then((res) => {
        let newlist = res.data.data;
        let options = [];
        if (res.data.data) {
          if (newlist?.length > 0) {
            for (let i = 0; i < newlist?.length; i = i + 1) {
              let item = {
                value: newlist[i].b_id,
                label: newlist[i].branch_name,
                type: newlist[i].branch_type,
              };
              options.push(item);
            }
          }
          this.departmentListFunc(res.data.data[0].b_id);
          this.setState({ branchList: options });
          this.setState({ branchValue: res.data.data[0].b_id });
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
    this.getDepartment(this.state.departmentValue);
    this.getTableData(this.state.departmentValue, this.state.type);
    this.getUserData(this.state.departmentValue);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.branchValue !== this.state.branchValue) {
      this.departmentListFunc(this.state.branchValue);
    }
    if (prevState.departmentValue !== this.state.departmentValue) {
      this.getDepartment(this.state.departmentValue);
      this.getTableData(this.state.departmentValue, this.state.type);
      this.getUserData(this.state.departmentValue);
      this.setState({ graphPageno: 0 });
    }

    if (prevState.graphPageno != this.state.graphPageno) {
      this.getDepartment(this.state.departmentValue);
      this.getTableData(this.state.departmentValue, this.state.type);
      this.getUserData(this.state.departmentValue);
    }

    if (prevState.calender !== this.state.calender) {
      this.getDepartment(this.state.departmentValue);
      this.getTableData(this.state.departmentValue, this.state.type);
      this.getUserData(this.state.departmentValue);
    }

    if (prevState.search !== this.state.search) {
      this.getTableData(this.state.departmentValue, this.state.type);
    }
  }

  tabChange(index) {
    this.setState({ type: index });
    this.getTableData(this.state.departmentValue, index);
  }

  toggleProBanner() {
    document.querySelector(".proBanner").classList.toggle("hide");
  }

  setDepartmentId = (e) => {
    localStorage.setItem("dashboard", e.target.value);
    this.setState({ question: {}, dataMock: [], table: {} });
    this.setState({ did: e.target.value });
    this.setState({ type: 0 });
    this.setState({ search: "" });
    let departmentId = e.target.value;
    this.setState({ DID: e.target.value });

    if (departmentId != "AllDepartments") {
      this.getDepartment(departmentId);
      this.getTableData(departmentId, this.state.type);
    }
  };

  excelDownload(data, question) {
    if (question == "question")
      exportFromJSON({
        data: data,
        fileName: `QuestionwiseData.xls`,
        exportType: exportFromJSON.types.xls,
      });
    else {
      exportFromJSON({
        data: data,
        fileName: `UserwiseData.xls`,
        exportType: exportFromJSON.types.xls,
      });
    }
  }

  pdfDownload(number) {
    if (number != "userdetails") {
      let search = this.state.search == "" ? 0.001 : this.state.search;

      axios
        .get(
          `${process.env.REACT_APP_BASE_URL_NEW}/api/dashboardpdf/${this.state.departmentValue}/${this.state.fromdate}/${this.state.todate}/${number}/${search}/${this.state.branchValue}`,
          {
            headers: {
              Authorization: process.env.REACT_APP_AUTH_KEY,
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("Network response was not ok");
          }

          const blob = new Blob([response.data], { type: "application/pdf" });

          // Create a temporary URL for the blob
          const url = URL.createObjectURL(blob);

          // Create a hidden link element
          const link = document.createElement("a");
          link.href = url;
          link.download = "QuestionwiseData.pdf";

          // Append the link to the body and simulate a click
          document.body.appendChild(link);
          link.click();

          // Clean up the temporary URL and remove the link element
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL_NEW}/api/department/users/${this.state.departmentValue}/${this.state.fromdate}/${this.state.todate}/2/${this.state.branchValue}`,
          {
            headers: {
              Authorization: process.env.REACT_APP_AUTH_KEY,
              "Content-Type": "application/json",
            },
            responseType: "blob", // Set the response type to 'blob'
          }
        )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("Network response was not ok");
          }

          const blob = new Blob([response.data], { type: "application/pdf" });

          // Create a temporary URL for the blob
          const url = URL.createObjectURL(blob);

          // Create a hidden link element
          const link = document.createElement("a");
          link.href = url;
          link.download = "UserwiseData.pdf";

          // Append the link to the body and simulate a click
          document.body.appendChild(link);
          link.click();

          // Clean up the temporary URL and remove the link element
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  toggleDropdown = (e) => {
    if (this.state.showDropdown) {
      this.setState({ showDropdown: false });
    } else {
      this.setState({ showDropdown: true });
    }
  };

  HandleSubmit = (e) => {
    this.setState({ showDropdown: false });
  };
  handleChange = (event) => {
    this.setState({ branchValue: event.target.value });
    this.setState({ graphPageno: 0 });
  };
  handleChangeDepartment = (event) => {
    this.setState({ departmentValue: event.target.value });
    this.setState({ graphPageno: 0 });
  };

  render() {
    localStorage.setItem("branchId", this.state.branchValue);
    localStorage.setItem("did", this.state.did);
    const slides = [];
    for (let i = 0; i < 12; i++) {
      slides.push(
        <SwiperSlide key={`slide-${i}`} style={{ listStyle: "none" }}>
          <div className="slide">
            <h3>{i}</h3>
          </div>
        </SwiperSlide>
      );
    }
    let today = new Date();
    let displayDate =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    let date = (today.getDate() < 10 ? "0" : "") + today.getDate();
    let requiredDate =
      today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)) + "-" + date;

    const form = {
      position: "relative",
      marginRight: "13px",
      marginBottom: "0  !important",
    };

    const searchicon = {
      position: "absolute",
      right: "10px",
      bottom: "10px",
      color: "#18A7E2",
    };

    const DropdownBranch = ({ label, value, options, onChange, id }) => {
      return (
        <label className="DropdownLabel">
          {id == "branch" ? (
            <select
              value={this.state.branchValue != "" && this.state.branchValue}
              className="form-control form_drop drops dept_selct select_rspo"
              onChange={onChange}
            >
              {this.state.branchList?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                  {option.type == "main" ? " (Main)" : ""}
                </option>
              ))}
            </select>
          ) : (
            <select
              value={
                this.state.departmentValue != "" && this.state.departmentValue
              }
              className="form-control form_drop drops dept_selct select_rspo"
              onChange={onChange}
            >
              {this.state.departmentList?.length > 0 ? (
                this.state.departmentList?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))
              ) : (
                <option>No departments</option>
              )}
            </select>
          )}
        </label>
      );
    };
    const columns = [
      {
        name: "User Name",
        selector: (row) => row.user_name,
        center: true,
        sortable: true,
      },
      {
        name: "Date",
        selector: (row) => row.created_date,
        center: true,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        center: true,
        sortable: true,
      },
      {
        name: "Mobile Number",
        selector: (row) => row.phone_number,
        center: true,
        sortable: true,
      },
      {
        name: "Satisfactory Level",
        selector: (row) => row.answer,
        center: true,
        sortable: true,

        conditionalCellStyles: [
          {
            when: (row) => row.answer == "sad",
            style: {
              backgroundColor: "#FF6D63",
              color: "white",
              "&:hover": {
                cursor: "pointer",
              },
            },
          },
          {
            when: (row) => row.answer == "neutral",
            style: {
              backgroundColor: "#F7CF56",
              color: "white",
              "&:hover": {
                cursor: "pointer",
              },
            },
          },
          {
            when: (row) => row.answer == "happy",
            style: {
              backgroundColor: "#8EE41E ",
              color: "white",
              "&:hover": {
                cursor: "not-allowed",
              },
            },
          },
          // You can also pass a callback to style for additional customization
          {
            when: (row) => row.answer < 600,
            style: (row) => ({
              backgroundColor: row.isSpecial ? "pink" : "inerit",
            }),
          },
        ],
      },
    ];

    const customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: "18px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    return (
      <React.Fragment>
        <LoadingOverlay
          active={this.state.loading}
          spinner={true}
          text="Loading.."
          styles={{
            overlay: (base) => ({
              ...base,
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 1100,
            }),
          }}
        >
          <div>
            <div className=" mb-3">
              <h3 className="page-title">Dashboard </h3>
            </div>
            <div className="page-header ">
              {this.state.departmentValue !== "AllDepartments" ? (
                <div className="">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="export export_respo"
                      variant="success"
                      id="dropdown-basic"
                    >
                      User Data
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={
                          this.state.userdata !== undefined &&
                          this.state.userdata !== null
                            ? () => this.pdfDownload("userdetails")
                            : undefined
                        }
                      >
                        <BsFillFileEarmarkPdfFill className="pdf_icon" />
                        PDF
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={
                          this.state.userdata !== undefined &&
                          this.state.userdata !== null
                            ? () =>
                                this.excelDownload(this.state.userdata, "user")
                            : undefined
                        }
                      >
                        <FaFileExcel className="excel_icon" />
                        EXCEL
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : (
                <></>
              )}
              <div className="d-flex justify-content-around">
                <DropdownBranch id="branch" onChange={this.handleChange} />
                <DropdownBranch
                  id="department"
                  onChange={this.handleChangeDepartment}
                />
              </div>
              <div>
                <div className="d-md-flex align-items-center dateDashboard_responsive">
                  <div className="d-md-flex  date_height ">
                    <div className="d-flex">
                      <input
                        type="date"
                        id="fromDate"
                        max={requiredDate}
                        onChange={this.changeFromDate}
                      />
                      <span className="picker_to align-self-center">to</span>
                      <input
                        type="date"
                        id="toDate"
                        min={
                          this.state.fromdate !== 0
                            ? this.state.fromdate.substr(0, 10)
                            : ""
                        }
                        max={requiredDate}
                        onChange={this.changeToDate}
                      />
                    </div>
                    <Button
                      className="calender_submit mx-2"
                      onClick={this.calenderSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.departmentValue != "AllDepartments" ? (
            this.state.questions && this.state.dataMock.length > 0 ? (
              <div className="pie">
                <div className="row">
                  {Object.keys(this.state.questions).map((key, index) => {
                    let data = this.state.dataMock[index].dataMock1;
                    // console.log(this.state.dataMock, "api");
                    let nps =
                      this.state.dataMock[index].dataMock1[1].value -
                      this.state.dataMock[index].dataMock1[0].value;
                    if (nps === 0) {
                      nps = 0;
                    }
                    let data1 = data.filter(function (item) {
                      return item.value !== 0;
                    });
                    // console.log(data1,"api")
                    return (
                      <div
                        className="col-md-4 grid-margin stretch-card"
                        key={index}
                      >
                        <div className="card">
                          <div className="card-body">
                            <div style={{ position: "relative" }}>
                              <PieChart
                                data={data1}
                                style={{
                                  fontFamily:
                                    '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
                                  fontSize: "8px",
                                  height: "150px",
                                }}
                                // radius={PieChart?.defaultProps?.radius - 6}
                                radius={50}
                                lineWidth={40}
                                segmentsStyle={{
                                  transition: "stroke .3s",
                                  cursor: "pointer",
                                }}
                                animate
                                label={({ dataEntry }) =>
                                  Math.round(dataEntry.percentage) + "%"
                                }
                                labelPosition={100 - 40 / 2}
                                labelStyle={{
                                  fill: "#fff",
                                  opacity: 0.75,
                                  pointerEvents: "none",
                                }}
                              />
                              <div className="nps1">{nps}%</div>
                            </div>
                            <div
                              id="traffic-chart-legend"
                              className="rounded-legend legend-vertical legend-bottom-left pt-4"
                            >
                              <p>{this.state.questions[key].question}</p>
                              <p className="text-rgt">
                                {this.state.questions[key].arabic}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={this.state.pagecountGraph}
                  marginPagesDisplayed={5}
                  pageRangeDisplayed={1}
                  onPageChange={this.handlePageClickGraph}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  forcePage={this.state.graphPageno}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
                <div className="row dash-align">
                  <div className="col-md-12 grid-margin stretch-card p-3 ">
                    <div className="card">
                      <div
                        className="card-body"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <BarChart
                          width={800}
                          height={500}
                          data={this.state.Bardata}
                        >
                          <CartesianGrid />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          {/* <Legend />   */}
                          <Bar
                            dataKey="Promoters"
                            radius={[5, 5, 5, 5]}
                            strokeWidth={30}
                            stackId="a"
                            fill="#8EE41E"
                          ></Bar>
                          <Bar
                            dataKey="Detractors"
                            radius={[5, 5, 5, 5]}
                            stackId="a"
                            fill="#FF6D63"
                          ></Bar>
                          <Bar
                            dataKey="Passives"
                            radius={[5, 5, 5, 5]}
                            stackId="a"
                            fill="#F7CF56"
                          ></Bar>
                        </BarChart>

                        <div
                          className="d-md-flex justify-content-center pb-3"
                          aria-label="breadcrumb"
                        >
                          <div className="ml-3">
                            <span className="legend-dot mr-2"></span>PROMOTERS
                          </div>
                          <div className="ml-3">
                            <span className="legend-dots mr-2"></span>DETRACTORS
                          </div>
                          <div className="ml-3">
                            <span className="legend-dotse mr-2"></span>PASSIVES
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*<Tab onClick ={()=>this.tabChange(index)} title={this.state.questions[key].question}>Question {index+1}</Tab>*/}
                <div className="tabs-list">
                  <Tabs>
                    <TabList className="row">
                      {this.state.questions &&
                        Object.keys(this.state.questions).map((key, index) => (
                          <Tab
                            key={index}
                            className="col-md-2 question_sty"
                            onClick={() => this.tabChange(index)}
                            title={this.state.questions[key].question}
                          >
                            Question {3 * this.state.graphPageno + index + 1}
                          </Tab>
                        ))}
                    </TabList>

                    {this.state.questions &&
                      Object.keys(this.state.questions).map((key, index) => {
                        return (
                          <TabPanel key={index}>
                            <div className="row dash-align mt-5">
                              <div className="col-md-7">
                                <p>{this.state.questions[key].question}</p>
                                <p>{this.state.questions[key].arabic}</p>
                                {/* Q {index+1}.{this.state.questions[key].question}*/}
                              </div>
                              <div className="drop col-md-3">
                                <Form>
                                  <Form.Group
                                    style={form}
                                    className=" form_head "
                                  >
                                    <Form.Control
                                      className="form_drop "
                                      value={this.state.search}
                                      onChange={(e) =>
                                        this.setState({
                                          search: e.target.value,
                                        })
                                      }
                                      placeholder="satisfactory level"
                                    />
                                    <i
                                      style={searchicon}
                                      className="fa fa-search search_icon"
                                      aria-hidden="true"
                                    ></i>
                                  </Form.Group>
                                </Form>
                              </div>
                              <div className="col-md-2">
                                {this.state.table.length > 0 && (
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      className="export"
                                      variant="success"
                                      id="dropdown-basic"
                                    >
                                      Export
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() =>
                                          this.pdfDownload(this.state.type)
                                        }
                                      >
                                        {" "}
                                        <BsFillFileEarmarkPdfFill className="pdf_icon" />
                                        PDF
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() =>
                                          this.excelDownload(
                                            this.state.table,
                                            "question"
                                          )
                                        }
                                      >
                                        <FaFileExcel className="excel_icon" />
                                        EXCEL
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                )}
                              </div>
                              {this.state.table.length > 0 ? (
                                <div className="col-md-12 grid-margin stretch-card">
                                  <div className="card mt-3">
                                    <div className="card-body">
                                      <div>
                                        <DataTable
                                          columns={columns}
                                          data={this.state.table}
                                          pagination
                                          theme="solarized"
                                          print="true"
                                          fixedHeader
                                          customStyles={customStyles}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <p>No Data found For search</p>
                              )}
                            </div>
                          </TabPanel>
                        );
                      })}
                  </Tabs>
                </div>
              </div>
            ) : (
              <p>NO DATA FOR SELECTED DEPARTMENT</p>
            )
          ) : (
            <Departments
              fromdate={this.state.fromdate}
              todate={this.state.todate}
              calender={this.state.calender}
              departmentId={this.state.departmentValue}
            />
          )}
        </LoadingOverlay>
      </React.Fragment>
    );
  }
}

export default Dashboard;
