import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { FaFileExcel } from "react-icons/fa";
import { IoCalendarSharp } from "react-icons/io5";
import ReactPaginate from "react-paginate";
import "jspdf-autotable";
import { Dropdown, Popover } from "react-bootstrap";
import { Button } from "react-bootstrap";
import exportFromJSON from "export-from-json";
import { saveAs } from "file-saver";
import eye from "../../assets/images/icons/eye.png";
import { Modal, CloseButton } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import submitarrow from "../../assets/images/icons/submitarrow.png";
import downloadbtn from "../../assets/images/icons/download.png";
import Tooltip from "react-bootstrap/Tooltip";
import Overlay from "react-bootstrap/Overlay";
import LoadingOverlay from "react-loading-overlay";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "./review.css";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

const Table = () => {
  let today = new Date();
  let date = (today.getDate() < 10 ? "0" : "") + today.getDate();
  let requiredDate =
    today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)) + "-" + date;
  const [dept, setDept] = useState([]);
  const [did, setDid] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pageno, setPageNo] = useState(0);
  const [pagecount, setPageCount] = useState(0);
  const [data, setData] = useState([]);
  const [change, setChange] = useState(1);
  const [reviewdata, setReviewdata] = useState([]);
  const [fromdate, setFromdate] = useState(0);
  const [todate, setTodate] = useState(0);
  const [calender, setCalender] = useState("");
  const [show, setShow] = useState(false);
  const [views, setViews] = useState([]);
  const [branch, setBranch] = useState({ list: [], id: "0" });
  const [department, setDepartment] = useState({ list: [], id: "0" });
  const [showTooltip, setShowTooltip] = useState(true);
  const [item, setItem] = useState(false);
  const a_id = localStorage.getItem("adminId");

  // const [selectedOption, setSelectedOption] = useState(null);
  let DID = !localStorage.getItem("review")
    ? did
    : localStorage.getItem("review");
  let limit = 10;

  const handlePageClick = (event) => {
    setPageNo(event.selected);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL_NEW}/api/branch_list/${a_id}`, {
        headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
      })
      .then((res) => {
        let newlist = res.data.data;
        let options = [];
        if (res.data.data) {
          if (newlist?.length > 0) {
            for (let i = 0; i < newlist?.length; i = i + 1) {
              // console.log(newlist[i], "item");
              let item = {
                value: newlist[i].b_id,
                label: newlist[i].branch_name,
                type: newlist[i].branch_type,
              };
              options.push(item);
            }
          } else {
            options = [];
          }
          setBranch({ id: res.data.data[0].b_id, list: options });
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/department_list/${a_id}/${branch?.id}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        let newlist = res.data.data;
        let options = [];
        if (res.data.data) {
          if (newlist?.length > 0) {
            for (let i = 0; i < newlist?.length; i = i + 1) {
              let item = {
                value: newlist[i].d_id,
                label: newlist[i].department,
              };
              options.push(item);
            }
          } else {
            options = [];
          }
          setDepartment({ id: res.data.data[0].d_id, list: options });
          setLoading(false);
        } else {
          setDepartment({ id: 0, list: [] });
          setLoading(false);
        }
      })
      .catch((e) => {
        alert(e.message);
      });
  }, [branch?.id, change]);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await axios
        .get(
          `${process.env.REACT_APP_BASE_URL_NEW}/api/getreviewsforadmin/${pageno}/${limit}/${department.id}/${fromdate}/${todate}/${a_id}`,
          { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } }
        )
        .then((res) => {
          let data = res.data.data;
          let total = res.data.totalPages;
          setData(data);
          setPageCount(total);
        });
      await axios
        .get(
          `${process.env.REACT_APP_BASE_URL_NEW}/api/getallreviews/${department.id}/${fromdate}/${todate}/excel/${a_id}`,
          { headers: { Authorization: process.env.REACT_APP_AUTH_KEY } }
        )
        .then((res) => {
          let data = res.data.data;
          setReviewdata(data);
        });
      setLoading(false);
    };

    fetch();
  }, [department.id, pageno, a_id, calender]);

  const handleClose = () => {
    setShow(false);
    setItem(false);
  };

  const handleShow = (QE, QA, A, E, U, P) => {
    // view.push({English:QE,Arabic:QA,Answer:A,Email:E,User:U,Number:P})
    setViews({
      English: QE,
      Arabic: QA,
      Answer: A,
      Email: E,
      User: U,
      Number: P,
    });
    setShow(true);
  };
  // console.log(views.English,"VIEWSSS")

  const changeFromDate = (e) => {
    if (e.target.value) {
      setFromdate(e.target.value + " 00:00:00");
    } else {
      setFromdate(0);
    }
  };

  const changeToDate = (e) => {
    if (e.target.value) {
      setTodate(e.target.value + " 23:59:59");
    } else {
      setTodate(0);
    }
  };

  const calenderSubmit = (e) => {
    if (fromdate !== 0 && todate !== 0) {
      setCalender(fromdate + todate);
    } else {
      setCalender(0);
    }
  };

  const download = () => {
    if (reviewdata?.length > 0) {
      exportFromJSON({
        data: reviewdata,
        fileName: `Reviews.xls`,
        exportType: exportFromJSON.types.xls,
      });
    }
  };

  const pdfDownload = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/getallreviews/${department.id}/${fromdate}/${todate}/pdf/${a_id}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
          responseType: "blob",
        }
      )

      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Network response was not ok");
        }
        const blob = new Blob([res.data], { type: "application/pdf" });
        const Url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = Url;
        link.download = "Reviews.pdf";

        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(Url);
        document.body.removeChild(link);
        setLoading(false);
        // saveAs(`${process.env.REACT_APP_BASE_URL_NEW}/${res.data.result.fileName}`, `Reviews.pdf`);
      });
  };

  const Dropdown = ({ id, onChange }) => {
    return (
      <div style={{ margin: "7px 7px 0px 0px" }}>
        <label>
          {id == "branch" ? (
            <select
              value={branch.id != "" && branch.id}
              className=" form-control form_drop drops dept_selct select_rspo"
              onChange={onChange}
            >
              {branch.list?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                  {option.type == "main" ? " (Main)" : ""}
                </option>
              ))}
            </select>
          ) : (
            <select
              value={department.id !== "" && department.id}
              className="form-control form_drop drops dept_selct select_rspo"
              onChange={onChange}
              style={{ width: "100px" }}
            >
              {department.list?.length === 0 ? (
                <option>No departments</option>
              ) : (
                <>
                  {department.list?.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </>
              )}
            </select>
          )}
        </label>
      </div>
    );
  };

  const handleChange = (event) => {
    setBranch({ ...branch, id: event.target.value });
    setChange(change + 1);
    setPageNo(0);
  };

  const handleChangeDepartment = (event) => {
    setDepartment({ ...department, id: event.target.value });
    setPageNo(0);
  };

  const popoverBottom = (
    <Popover
      placement="bottom"
      className="popOver"
      positionLeft={200}
      positionTop={50}
    >
      <div
        style={{ display: "flex" }}
        onClick={data?.length > 0 ? pdfDownload : undefined}
      >
        <BsFillFileEarmarkPdfFill className="pdf_icon" />
        <p>PDF</p>
      </div>
      <div style={{ display: "flex" }} onClick={download}>
        <FaFileExcel className="excel_icon" />
        <p>Excel</p>
      </div>
    </Popover>
  );

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner={true}
        text="Loading.."
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1100,
          }),
          content: (base) => ({
            ...base,
            // Add any custom styles for the content here
          }),
        }}
      >
        <div>
          <div className="mb-2">
            <h3 className="page-title">Reviews </h3>
          </div>
          <div className="mb-4 d-md-flex   align-items-center justify-content-end">
            <div className=" d-md-flex ml-auto flex-lg-nowrap flex-md-wrap">
              <div className="d-flex dateDashboard_responsive pl-4">
                <input
                  type="date"
                  id="fromDate"
                  max={requiredDate}
                  onChange={changeFromDate}
                  className="px-3"
                />{" "}
                <span className="picker_to mt-2 align-self-center">to</span>
                <input
                  type="date"
                  className="px-3"
                  id="toDate"
                  min={fromdate !== 0 ? fromdate.substr(0, 10) : ""}
                  max={requiredDate}
                  onChange={changeToDate}
                />
              </div>
              <Button className="calender_submit mx-2" onClick={calenderSubmit}>
                Submit
              </Button>
              <div className="d-flex align-items-center right-menu ">
                <Dropdown
                  id="branch"
                  //value={branch.list}
                  onChange={handleChange}
                />
                <Dropdown
                  id="department"
                  //value={branch.list}
                  onChange={handleChangeDepartment}
                />

                <div className="ToolTipBox">
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={popoverBottom}
                  >
                    <img
                      alt="pdf"
                      style={{ cursor: "pointer" }}
                      src={downloadbtn}
                    />
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </div>
          <div className="tableaboveDiv" style={{ overflow: "auto" }}>
            <table className="table table-responsive   table_style">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Question</th>
                  <th>Answers</th>
                  <th>Email Id</th>
                  <th>UserName</th>
                  <th>MobileNumber</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data ? (
                  data.map((values, index) => (
                    <tr key={index}>
                      <td className="td nowrap_class">
                        {pageno * 10 + index + 1}
                      </td>
                      <td
                        className="td nowrap_class"
                        title={values.questionname_e}
                      >
                        <span>{values.questionname_e}</span>
                        <br />
                        <br />
                        <span>{values.questionname_a}</span>
                      </td>
                      <td className="td nowrap_class" title={values.answer}>
                        {values.answer}
                      </td>
                      <td className="td nowrap_class" title={values.email}>
                        {values.email}
                      </td>
                      <td className="td nowrap_class" title={values.user_name}>
                        {values.user_name}
                      </td>
                      <td
                        className="td nowrap_class"
                        title={values.phone_number}
                      >
                        {values.phone_number}
                      </td>
                      <td>
                        <img
                          className="Btd_img"
                          onClick={() =>
                            handleShow(
                              values.questionname_e,
                              values.questionname_a,
                              values.answer,
                              values.email,
                              values.user_name,
                              values.phone_number
                            )
                          }
                          src={eye}
                          alt="view"
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>No data found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pagecount}
            forcePage={pageno}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />

          <Modal show={show} onHide={handleClose} animation={true}>
            <Modal.Title className="modal_title"></Modal.Title>
            <Modal.Body>
              <div onClick={handleClose}>
                <CloseButton />
              </div>

              <div className="p-4">
                <label className="review_clr">English:</label>

                <div className=" review_brdr">{views.English}</div>

                <label className="mt-3 review_clr">Arabic:</label>
                <div className="review_brdr">{views.Arabic}</div>

                <label className="mt-3 review_clr">Answer:</label>
                <div className="review_brdr ">{views.Answer}</div>

                <label className=" mt-3 review_clr">Email:</label>
                <div className=" review_brdr">{views.Email}</div>

                <label className="mt-3 review_clr">UserName:</label>
                <div className="review_brdr">{views.User}</div>

                <label className=" mt-3 review_clr">PhoneNumber:</label>
                <div className="col-md-12 review_brdr">{views.Number}</div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </LoadingOverlay>
    </>
  );
};

export default Table;
