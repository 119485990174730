import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./react-tabs.css";
import Question1 from "./question1_tab.js";
import axios from "axios";
import "react-circular-progressbar/dist/styles.css";
import "./progress-bar.css";
import "../dashboard/recharts.css";
import ReactPaginate from "react-paginate";

const Departments = (props) => {
  const [question, setQuestion] = useState({});
  const [page, setPage] = useState(0);
  const [questioncheck, setQuestionCheck] = useState("");
  const [departments, setDepartments] = useState([]);
  const [datacheck, setDataCheck] = useState("");
  const [type, setType] = useState(0);
  const [pagecount, setpageCount] = useState(0);

  let departmentId = props.departmentId;
  let fromdate = props.fromdate;
  let todate = props.todate;
  let calendar = props.calender;
  let branchId = localStorage.getItem("branchId");

  useEffect(() => {
    fetch();
  }, [calendar, page]);

  const fetch = async () => {
    await axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL_NEW
        }/api/dashboardbydepartment/${departmentId}/${fromdate}/${todate}/${page}/${3}/${branchId}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        let response = res.data.data;
        setQuestion(response);
        setpageCount(res.data.totalPages);
        let check1 = [];
        if (response) {
          Object.keys(response).map((key, index) => {
            let sad, neutral, happy;
            let totalcount =
              response[key].sad + response[key].neutral + response[key].happy;
            sad = Math.round((response[key].sad / totalcount) * 100);
            neutral = Math.round((response[key].neutral / totalcount) * 100);
            happy = Math.round((response[key].happy / totalcount) * 100);
            check1.push({
              sadcount: response[key].sad,
              neutralcount: response[key].neutral,
              happycount: response[key].happy,
              sadval: sad,
              neutralval: neutral,
              happyval: happy,
              question: response[key].question,
              arabic: response[key].arabic,
            });
          });
        }
        setDataCheck(check1);
      })
      .catch((err) => {
        alert(err.message);
      });

    await axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL_NEW
        }/api/dashboardbydepartment/${departmentId}/${fromdate}/${todate}/${page}/${3}/${branchId}`,
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        let response = res.data.data;
        let checker = [];
        if (response) {
          Object.keys(response).map((key, index) => {
            checker.push(response[key]);
          });
        }
        setQuestionCheck(checker);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const tabChange = (index) => {
    setType(index);
  };

  const handlePageClickGraph = (e) => {
    setPage(e.selected);
    setType(0);
  };

  return (
    <div className="tabs-list">
      <div>
        {questioncheck.length > 0 ? (
          <Tabs selectedIndex={type}>
            <TabList className="row d-flex justify-content-center">
              {questioncheck.length > 0 &&
                questioncheck.map((value, index) => (
                  <Tab
                    className="col-md-2"
                    key={index}
                    onClick={() => tabChange(index)}
                    style={{ cursor: "pointer" }}
                  >
                    Question {3 * page + index + 1}
                  </Tab>
                ))}
            </TabList>

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={pagecount}
              forcePage={page}
              marginPagesDisplayed={3}
              pageRangeDisplayed={3}
              onPageChange={handlePageClickGraph}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
            {questioncheck.length > 0 &&
              datacheck.length > 0 &&
              questioncheck.map((value, index) => (
                <TabPanel key={index}>
                  <Question1
                    data={datacheck[type]}
                    data1={questioncheck[index]}
                    type={type}
                    index={index}
                    fromdate={fromdate}
                    todate={todate}
                    branchId={branchId}
                  />
                </TabPanel>
              ))}
          </Tabs>
        ) : (
          <p>NO DATA FOR SELECTED DEPARTMENT</p>
        )}
      </div>
    </div>
  );
};
export default Departments;
