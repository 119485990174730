import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Trans } from "react-i18next";
import Swal from "sweetalert2";
import dash from "../../assets/images/icons/dashboard.png";
import review from "../../assets/images/icons/reviews.png";
import question from "../../assets/images/icons/question.png";
import department from "../../assets/images/icons/department.png";
import management from "../../assets/images/icons/management.png";
import saver from "../../assets/images/icons/saver.png";
import branches from "../../assets/images/icons/branches.png";
import logout from "../../assets/images/icons/logout.png";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type:
        localStorage.getItem("type") == null ? 0 : localStorage.getItem("type"),
    };
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    // Object.keys(this.state).forEach(i => {
    //   this.setState({[i]: false});
    // });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  logOut() {
    Swal.fire({
      title: "Do you want to Logout?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#000000",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        this.props.history.push("/");
      }
    });
  }

  render() {
    return (
      <>
        {this.state.type == "2" ? (
          <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
              <li
                className={
                  this.isPathActive("/subDashboard")
                    ? "nav-item active hover-open"
                    : "nav-item"
                }
              >
                <Link
                  className={`nav-link ${
                    this.isPathActive("/subDashboard") ? "menuActiveDash" : ""
                  }`}
                  to="/subDashboard"
                >
                  <img
                    alt=""
                    className={
                      this.isPathActive("/subDashboard")
                        ? "imageHoverFilter"
                        : "oneFilterClass"
                    }
                    src={dash}
                  />
                  <span className="menu-title">
                    <Trans>Dashboard</Trans>
                  </span>
                </Link>
              </li>
              <li
                className={
                  this.isPathActive("/Subreviews")
                    ? "nav-item active  hover-open"
                    : "nav-item"
                }
              >
                <Link
                  className={`nav-link ${
                    this.isPathActive("/Subreviews") ? "menuActiveDash" : ""
                  }`}
                  to="/Subreviews"
                >
                  <img
                    alt=""
                    className={
                      this.isPathActive("/Subreviews") ? "imageHoverFilter" : ""
                    }
                    src={review}
                  />
                  <span className="menu-title">
                    <Trans>Reviews</Trans>
                  </span>
                </Link>
              </li>

              <li
                className={
                  this.isPathActive("/subQuestion")
                    ? "nav-item active  hover-open"
                    : "nav-item"
                }
              >
                <Link
                  className={`nav-link ${
                    this.isPathActive("/subQuestion") ? "menuActiveDash" : ""
                  }`}
                  to="/subQuestion"
                >
                  <img
                    alt=""
                    className={
                      this.isPathActive("/subQuestion")
                        ? "imageHoverFilter"
                        : ""
                    }
                    src={question}
                  />
                  <span className="menu-title">
                    <Trans>Questions</Trans>
                  </span>
                </Link>
              </li>

              <li
                className={
                  this.isPathActive("/subDepartment")
                    ? "nav-item active  hover-open"
                    : "nav-item"
                }
              >
                <Link
                  className={`nav-link ${
                    this.isPathActive("/subDepartment") ? "menuActiveDash" : ""
                  }`}
                  to="/subDepartment"
                >
                  <img
                    alt=""
                    className={
                      this.isPathActive("/subDepartment")
                        ? "imageHoverFilter"
                        : ""
                    }
                    src={department}
                  />
                  <span className="menu-title">
                    <Trans>Departments</Trans>
                  </span>
                </Link>
              </li>

              <li
                className={
                  this.isPathActive("/subcomplaint")
                    ? "nav-item active  hover-open"
                    : "nav-item"
                }
              >
                <Link
                  className={`nav-link ${
                    this.isPathActive("/subcomplaint") ? "menuActiveDash" : ""
                  }`}
                  to="/subcomplaint"
                >
                  <img
                    alt=""
                    className={
                      this.isPathActive("/subcomplaint")
                        ? "imageHoverFilter"
                        : ""
                    }
                    src={management}
                  />
                  <span className="menu-title">
                    <Trans>Complaint Management</Trans>
                  </span>
                </Link>
              </li>
              <li
                className={
                  this.isPathActive("/subScreenSaver")
                    ? "nav-item active  hover-open"
                    : "nav-item"
                }
              >
                <Link
                  className={`nav-link ${
                    this.isPathActive("/subScreenSaver") ? "menuActiveDash" : ""
                  }`}
                  to="/subScreenSaver"
                >
                  <img
                    alt=""
                    className={
                      this.isPathActive("/subScreenSaver")
                        ? "imageHoverFilter"
                        : ""
                    }
                    src={saver}
                  />
                  <span className="menu-title">
                    <Trans>Screen saver</Trans>
                  </span>
                </Link>
              </li>

              <li
                className={
                  this.isPathActive("/charts") ? "nav-item active" : "nav-item"
                }
                onClick={() => this.logOut()}
              >
                <div
                  className={
                    this.state.chartsMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  // onClick={() => this.toggleMenuState("")}
                  data-toggle="collapse"
                >
                  <img src={logout} alt="logout" />
                  <span className="menu-title">
                    <Trans>Logout</Trans>
                  </span>
                </div>
              </li>
            </ul>
          </nav>
        ) : (
          <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
              <li
                className={
                  this.isPathActive("/dashboard")
                    ? "nav-item active hover-open"
                    : "nav-item"
                }
              >
                <Link
                  className={`nav-link ${
                    this.isPathActive("/dashboard") ? "menuActiveDash" : ""
                  }`}
                  to="/dashboard"
                >
                  <img
                    alt=""
                    className={
                      this.isPathActive("/dashboard")
                        ? "imageHoverFilter"
                        : "oneFilterClass"
                    }
                    src={dash}
                  />
                  <span className="menu-title">
                    <Trans>Dashboard</Trans>
                  </span>
                </Link>
              </li>

              <li
                className={
                  this.isPathActive("/reviews")
                    ? "nav-item active  hover-open"
                    : "nav-item"
                }
              >
                <Link
                  className={`nav-link ${
                    this.isPathActive("/reviews") ? "menuActiveDash" : ""
                  }`}
                  to="/reviews"
                >
                  <img
                    alt=""
                    className={
                      this.isPathActive("/reviews") ? "imageHoverFilter" : ""
                    }
                    src={review}
                  />
                  <span className="menu-title">
                    <Trans>Reviews</Trans>
                  </span>
                </Link>
              </li>

              <li
                className={
                  this.isPathActive("/questions")
                    ? "nav-item active  hover-open"
                    : "nav-item"
                }
              >
                <Link
                  className={`nav-link ${
                    this.isPathActive("/questions") ? "menuActiveDash" : ""
                  }`}
                  to="/questions"
                >
                  <img
                    alt=""
                    className={
                      this.isPathActive("/questions") ? "imageHoverFilter" : ""
                    }
                    src={question}
                  />
                  <span className="menu-title">
                    <Trans>Questions</Trans>
                  </span>
                </Link>
              </li>

              <li
                className={
                  this.isPathActive("/department")
                    ? "nav-item active  hover-open"
                    : "nav-item"
                }
              >
                <Link
                  className={`nav-link ${
                    this.isPathActive("/department") ? "menuActiveDash" : ""
                  }`}
                  to="/department"
                >
                  <img
                    alt=""
                    className={
                      this.isPathActive("/department") ? "imageHoverFilter" : ""
                    }
                    src={department}
                  />
                  <span className="menu-title">
                    <Trans>Departments</Trans>
                  </span>
                </Link>
              </li>

              {/* Brances nav Item */}
              <li
                className={
                  this.isPathActive("/branches")
                    ? "nav-item active  hover-open"
                    : "nav-item"
                }
              >
                <Link
                  className={`nav-link ${
                    this.isPathActive("/branches") ? "menuActiveDash" : ""
                  }`}
                  to="/branches"
                >
                  <img
                    alt=""
                    className={
                      this.isPathActive("/branches") ? "imageHoverFilter" : ""
                    }
                    src={branches}
                  />
                  <span className="menu-title">
                    <Trans>Branches</Trans>
                  </span>
                </Link>
              </li>

              <li
                className={
                  this.isPathActive("/management")
                    ? "nav-item active  hover-open"
                    : "nav-item"
                }
              >
                <Link
                  className={`nav-link ${
                    this.isPathActive("/management") ? "menuActiveDash" : ""
                  }`}
                  to="/management"
                >
                  <img
                    alt=""
                    className={
                      this.isPathActive("/management") ? "imageHoverFilter" : ""
                    }
                    src={management}
                  />
                  <span className="menu-title">
                    <Trans>Complaint Management</Trans>
                  </span>
                </Link>
              </li>
              <li
                className={
                  this.isPathActive("/screen_saver")
                    ? "nav-item active  hover-open"
                    : "nav-item"
                }
              >
                <Link
                  className={`nav-link ${
                    this.isPathActive("/screen_saver") ? "menuActiveDash" : ""
                  }`}
                  to="/screen_saver"
                >
                  <img
                    alt=""
                    className={
                      this.isPathActive("/screen_saver")
                        ? "imageHoverFilter"
                        : ""
                    }
                    src={saver}
                  />
                  <span className="menu-title">
                    <Trans>Screen saver</Trans>
                  </span>
                </Link>
              </li>

              <li
                className={
                  this.isPathActive("/charts") ? "nav-item active" : "nav-item"
                }
                onClick={() => this.logOut()}
              >
                <div
                  className={
                    this.state.chartsMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  // onClick={() => this.toggleMenuState("")}
                  data-toggle="collapse"
                >
                  <img src={logout} alt="logout" />
                  <span className="menu-title">
                    <Trans>Logout</Trans>
                  </span>
                </div>
              </li>
            </ul>
          </nav>
        )}
      </>
    );
  }

  isPathActive(path) {
    // if(path!='reviews'){
    //   localStorage.removeItem("review")
    // }
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("li");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        // if(body.classList.contains('sidebar-icon-only')) {
        el.classList.add("hover-open");
        el.querySelector("img").classList.add("normalMouseFilter");

        // }
      });
      el.addEventListener("mouseout", function () {
        // alert("Hello")
        if (el.classList.contains("active")) {
          el.querySelector("img").classList.remove("normalMouseFilter");
        } else {
          el.classList.remove("hover-open");
          el.querySelector("img").classList.remove("normalMouseFilter");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
