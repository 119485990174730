import React, { Component, useEffect, useState } from "react";
import "./admin_login.css";
import Cancel from "../../assets/images/icons/cancel.svg";
import Logins from "../../assets/images/icons/login.svg";
import { Link, useHistory, hashHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { isLogin, login } from "../routeIndex.js";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min.js";

const Login = () => {
  const [passwordType, setPasswordType] = useState("password");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let [message, setMessage] = useState("");
  let history = useHistory();

  useEffect(() => {
    const userType = localStorage.getItem("type");
    if (userType === "1") {
      // If user type is admin, redirect to the dashboard
      history.replace("/dashboard");
    } else if (userType === "2") {
      // If user type is subadmin or other, redirect to the subDashboard
      history.replace("/subDashboard");
    } else {
      history.replace("/");
    }
  }, []);

  const onSubmit = (data, event) => {
    event.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL_NEW}/api/adminlogin`,
        { email: data.Username, password: data.Password },
        {
          headers: { Authorization: process.env.REACT_APP_AUTH_KEY },
        }
      )
      .then((res) => {
        //console.log(res.data.userid,"id")
        if (res.data.status == "Success") {
          if (res.data.type == "1") {
            localStorage.setItem("adminId", res.data.admin_id);
            localStorage.setItem("key", res.data.key);
            localStorage.setItem("type", res.data.type);
            login({ type: res.data.type });
            history.replace("/dashboard");
          } else {
            login({ type: res.data.type });
            localStorage.setItem("adminId", res.data.admin_id);
            localStorage.setItem("subadmin_id", res.data.subadmin_id);
            localStorage.setItem("branch_id", res.data.branch_id);
            localStorage.setItem("type", res.data.type);
            history.replace("/subDashboard");
          }
        } else {
          setMessage(res.data.message);
        }
      })
      .catch((error) => {
        // Handle any errors during the API call
        console.error("Error during login:", error);
      });
  };

  const handleShowPass = () => {
    //  console.log('click')
    let pwd = document.getElementById("passwordField");
    if (pwd.type === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <React.Fragment>
      <div className="row h-100">
        <div className="offset-lg-2 col-lg-8">
          <div className="card_respo  h-100 d-flex align-items-center">
            <div className="card card1">
              <div className="card card2">
                <div className="card card3">
                  <div className=" mt-4 text-center">
                    <h4 className="header">Admin Login</h4>
                  </div>
                  <br />
                  <div className="body">
                    <form className="forms" autoComplete="off">
                      <div className="row offset-md-1 align-items-center">
                        <div className="col-md-3">
                          <label className="lable_title">Username</label>
                        </div>
                        <div className="col-md-7">
                          <input
                            className="brdr_clr"
                            type="text"
                            {...register("Username", {
                              required: true,
                              pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                            })}
                          />
                          {errors.Username &&
                            errors.Username.type === "required" && (
                              <p className="error mt-0">
                                Field cannot be Empty
                              </p>
                            )}
                          {errors.Username &&
                            errors.Username.type === "pattern" && (
                              <p className="error mt-0">
                                please enter valid Email
                              </p>
                            )}
                        </div>
                      </div>
                      <br></br>
                      <div className="row offset-md-1 align-items-center">
                        <div className="col-md-3">
                          <label className="lable_title">Password</label>
                        </div>
                        <div className="col-md-7">
                          <input
                            id="passwordField"
                            className="brdr_clr"
                            type={passwordType}
                            {...register("Password", { required: true })}
                          />
                          <span type="button" onClick={handleShowPass}>
                            {" "}
                            {passwordType === "password" ? (
                              <FaEyeSlash className="fa_eye" />
                            ) : (
                              <FaEye className="fa_eye" />
                            )}
                          </span>
                          {errors.Password &&
                            errors.Password.type === "required" && (
                              <p className="error mt-0">
                                Field cannot be Empty
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="row offset-md-1">
                        <div className="col-md-3"></div>
                        <br />
                        <div className="col-md-7 mt-2">
                          <Link to="/forgot_password" className="forgot_label">
                            Forgot password?
                          </Link>
                          <p className="error">{message}</p>
                        </div>
                        <br />
                      </div>
                    </form>
                    <div className="row pt-4 pb-4">
                      {/* <div className='col-sm-6 col-6 text-center'>
                        <img src={Cancel} alt='cancel' />
                      </div> */}

                      <div className=" offset-lg-6 col-sm-6 col-6 text-center">
                        <img
                          src={Logins}
                          alt="login"
                          style={{ cursor: "pointer" }}
                          onClick={handleSubmit(onSubmit)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Login;
